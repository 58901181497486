import { useEffect, useState } from "react";
import { get } from "../config/api";

const useFetch = ({
    api,
    initFilter = {},
    readyToFetch = true,
}) => {
    const [entities, setEntities] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [filter, setFilter] = useState(initFilter);
    const [ready, setReady] = useState(readyToFetch);

    useEffect(() => {

        const fetchEntity = () => {
            if (!api) return;
    
            const params = new URLSearchParams({
                ...filter
            });
            
            setLoading(true);
            get(`${api}?${params.toString()}`)
            .then(res => {
                setEntities(res['data']);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
        }
        if (ready){
            fetchEntity();
        }
    }, [filter]);


    return {
        entities,
        isLoading,
        error,
        handleFilterChange: setFilter,
        setReady
    }
}

export default useFetch;