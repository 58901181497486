import { Typography } from "@material-ui/core";
import clsx from "clsx";

const PageTitle = ({
    children,
    className,
    variant = "h1"
}) => (
    <Typography variant={variant} className={clsx(className)}>
        {children}
    </Typography>
);

export default PageTitle;