import { useCallback, useState } from "react"
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { localeToLangCode } from "../helper/localeConvert";

const useAccordion = ({
    initialProgress = 0,
    totalNumOfAccordion,
    onSubmit = () => {}
}) => {

    const history = useHistory();
    const intl = useIntl();
    const [progress, setProgress] = useState(initialProgress);


    const handleForward = useCallback(order => e => {
        if (order < totalNumOfAccordion - 1){
            setProgress(order + 1);
        }else{
            onSubmit();
        }
    }, []);

    const handleBackward = useCallback(order => e => {
        if (order > 0){
            setProgress(order - 1);
        }else{
            history.push(`/${localeToLangCode(intl.locale)}`);
        }
    }, [history]);

    const toProgress = order => e => {
        setProgress(order);
    }


    return {
        progress,
        setProgress,
        handleForward,
        handleBackward,
        toProgress
    }
}

export default useAccordion;