import { FormControl, FormHelperText, makeStyles, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formTextInputWrapper: {
        width: '100%'
    },
    formTextInput: {
        padding: '0px 15px',
        width: '100%',
        borderRadius: 50,
        fontSize: 24,
    }
}));

const BookingFormSelectInput = ({
    error,
    helperText,
    green,
    value,
    options,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.formTextInputWrapper}>
            <Select
                native
                className={classes.formTextInput}
                error={error}
                value={value}
                {...rest}
            >
                {
                    (options || []).map(option => <option value={option.value} disabled={option.disable}>{option.label || option.value}</option>)
                }
            </Select>
            {
                error && <FormHelperText error>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    );
}

export default BookingFormSelectInput;