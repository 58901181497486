export const getLangCode = lang => {
    if (lang == 'zh'){
        return "3";
    }

    if (lang == 'zh-hant'){
        return "2";
    }

    return "1";
}