import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { formatTime } from "../../helper/dateTimeFormat";
import BackToHomeButton from "../BackToHomeButton";
import ConfirmLogo from "../ConfirmLogo";
import InfoText from "../InfoText";
import SystemTitle from "../SystemTitle";
import {React} from 'react';
import PageTitle from "../PageTitle";
import fieldLangConvertor from "../../helper/fieldLangConvertor";

const useStyles = makeStyles((theme) => ({
    container: {
        '& > div': {
            marginTop: 30
        },
        maxWidth: 890,
        margin: 'auto'
    },
    button: {
        width: 250,
    }
}));

const BookingDetails = ({
    recordState,
    title,
    subTitle
}) => {

    const intl = useIntl();

    const classes = useStyles();

    const recipient = recordState.primary_contact_type_id == 1 ? recordState.mobile : recordState.email;

    const secondaryContact = recordState.primary_contact_type_id == 1 ? 'email' : 'mobile';

    const secondaryRecipient = recordState[secondaryContact];

    return (
        <Container>
            <SystemTitle><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>

            <ConfirmLogo/>

            <PageTitle>
                {title}
            </PageTitle>

            <Typography variant="h2">
                {subTitle}
            </Typography>

            {
                recordState.session.purpose_id == 2 ? 
                <Typography variant="h2">
                    <FormattedMessage id="booking.detail.enquiry.remark"/>
                </Typography>
                :
                <Typography variant="h2">
                    <FormattedMessage id="booking.detail.gift.remark"/>
                </Typography>
            }
            
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="form.name"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{recordState.name}</InfoText>  
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="form.primary.contact"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{recordState.primary_contact_type_id == 1 ? <FormattedMessage id="booking.detail.mobile"/> : <FormattedMessage id="booking.detail.email"/>}</InfoText>
                        <InfoText>{recipient}</InfoText>    
                    </Grid>

                    {
                        secondaryRecipient && 
                        <Grid item xs={6}>
                            <InfoText><FormattedMessage id="form.second.contact"/>:</InfoText>           
                        </Grid>
                    }

                    {
                        secondaryRecipient && 
                        <Grid item xs={6}>
                            <InfoText>{recordState.primary_contact_type_id == 1 ? <FormattedMessage id="booking.detail.email"/> : <FormattedMessage id="booking.detail.mobile"/>}</InfoText>
                            <InfoText>{secondaryRecipient}</InfoText>    
                        </Grid>
                    }

                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="managepage.listing.service"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{recordState.session.purpose_id == 1 ? <FormattedMessage id="giftpage.title"/> : <FormattedMessage id="complaintpage.title"/>}</InfoText>   
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="managepage.listing.location"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{fieldLangConvertor(recordState.session.branch, 'branch_name', intl.locale)}</InfoText>  
                        <InfoText>{fieldLangConvertor(recordState.session.branch, 'branch_address', intl.locale)}</InfoText>   
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="managepage.listing.datetime"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{`${recordState.session.date} ${formatTime(recordState.session.start_time)}`}</InfoText>   
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText><FormattedMessage id="managepage.listing.ref"/>:</InfoText>           
                    </Grid>

                    <Grid item xs={6}>
                        <InfoText>{recordState.ref_code}</InfoText>   
                    </Grid>
                </Grid>
                <BackToHomeButton/>
                
            </div>
            
        </Container>
    );
}

export default BookingDetails;