import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {React} from 'react';

const StyledRadio = withStyles({
    root: {
      color: '#22A28D !important',
      '&.Mui-disabled': {
        color: '#939393 !important',
      },
      '&$checked': {
        color: '#22A28D !important',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default StyledRadio;
