import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Fragment, React } from "react";
import { FormattedMessage} from "react-intl";
import { useHistory } from "react-router";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    langButton: {
        cursor: 'pointer',
        border: 0,
        backgroundColor: 'transparent',
        padding: 0,
        fontSize: 16,
        [theme.breakpoints.down("xs")]:{
            fontSize: 16
        },

    }
}));

const computeFirstLangAndSecondLang = (currLang) => {

    let firstLang = 'zh-hant';
    let secondLang = 'zh';

    if (currLang == 'zh-hant'){
        firstLang = 'en';
        secondLang = 'zh'
    }

    if (currLang == 'zh'){
        firstLang = 'en';
        secondLang = 'zh-hant'
    }

    return {
        firstLang,
        secondLang
    };
}

const LangSwitcher = ({
    lang,
    setLang
}) => {

    const classes = useStyles();

    const history = useHistory();

    const {firstLang, secondLang} = computeFirstLangAndSecondLang(lang);

    const handleLangClick = lang => e => {
        history.push(`/${localeToLangCode(lang)}`);
        //setLang(lang);
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item>
                    <button className={classes.langButton} onClick={handleLangClick(firstLang)}><FormattedMessage id="header.langSwitcher.firstLang"/></button>
                </Grid>

                <Grid item>
                    <button className={classes.langButton} onClick={handleLangClick(secondLang)}><FormattedMessage id="header.langSwitcher.secondLang"/></button>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default LangSwitcher;