import axios from 'axios';

let port = "";
if (window.location.port){
  port = ":8001";
}

const API_ENDPOINT = `${window.location.protocol}//${window.location.hostname}${port}`;
const PUBLIC_PATH = `${window.location.protocol}//${window.location.hostname}${port}`;

const apiRequest = (prefix='/api/frontend/', base=API_ENDPOINT) => {

    const params = {
        baseURL: base + prefix,
    }

    const headers = {
        'Conetent-Type' : 'application/json',
    };

    params['headers'] = headers;

    const instance = axios.create(params);

    return instance;
}

const get = async (endpoint, data) => {
    const res = await apiRequest().get(endpoint, data);
    return res.data;
}

const post = async (endpoint, data) => {
    const res = await apiRequest().post(endpoint, data);
    return res.data;
}

const put = async (endpoint, data) => {
    const res = await apiRequest().put(endpoint, data);
    return res.data;
}

const _delete = async (endpoint, data) => {
    const res = await apiRequest().delete(endpoint, data);
    return res.data;
}

const getURLFromPublic = (path) => {
    return `${PUBLIC_PATH}/${path}`;
}

export {
    apiRequest,
    get,
    post,
    put,
    _delete,
    getURLFromPublic
}
