import { Box, Collapse, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useContext } from "react";
import { AccordionContext } from "../../context/AccordionContext";
import StyledButton from "../StyledButton";
import {React} from 'react';


const useStyles = makeStyles((theme) => ({
    bookingAccordionWrapper: {
        backgroundColor: theme.palette.primary.main,
        height: 75,
        width: '100%',
        borderRadius: 50,
        cursor: 'pointer',
        padding: '0 25px',
        [theme.breakpoints.down("sm")]:{
            
        }
    },
    expandWrapper: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: 20,
    },
    bookingAccordionTitle: {
        color: '#000000',
        fontWeight: 'bold'
    },
    bookingAccordionValue: {
        [theme.breakpoints.down("sm")]:{
            display: 'none'
        }
    },
    expandTitle: {
        color: '#126471'
    },
    hidden: {
        display: 'none'
    },
    controlAreaRoot: {
        marginTop: 10,
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]:{
            justifyContent: 'center'
        }
    },
    controlAreaBackwardButton: {
        order: 1,
        [theme.breakpoints.down("sm")]:{
            order: 2,
        }
    },
    controlAreaForwardButton: {
        order: 2,
        [theme.breakpoints.down("sm")]:{
            order: 1,
        }
    }
}));

const ProgressControlArea = ({
    handleForward,
    handleBackward,
    nextText,
    backText,
    forwardAble,
    helperText
}) => {

    const classes = useStyles();

    return (
        <Grid className={classes.controlAreaRoot} container spacing={3}>

            <Grid item xs={12} md={4}>
                {helperText}
            </Grid>
                                
            <Grid item xs={12} md={6} container spacing={3} className={classes.controlAreaButtonGroup}>
                <Grid item xs={12} md={6} className={classes.controlAreaBackwardButton}>
                    <StyledButton
                        label={backText}
                        onClick={handleBackward}
                        className="MuiTypography-h3"
                        variant="displayBlock"
                    />
                </Grid>
                
                <Grid item xs={12} md={6} className={classes.controlAreaForwardButton}>
                    <StyledButton
                        label={nextText}
                        filled
                        onClick={handleForward}
                        disabled={!forwardAble}
                        type="button"
                        className="MuiTypography-h3"
                        variant="displayBlock"
                    />
                </Grid>
            </Grid>

        </Grid>
    );
}

const BookingAccordion = ({
    title,
    value,
    children,
    nextText = "Next",
    backText = "Back",
    order,
    forwardAble = true,
    helperText
}) => {

    const {progress, handleForward, handleBackward, toProgress} = useContext(AccordionContext);

    const expand = progress === order;
    const hidden = (progress < order) && !value;

    const classes = useStyles();
    return (
        <Fragment>
            <Grid 
                container 
                className={clsx(
                    classes.bookingAccordionWrapper, 
                    {
                        [classes.expandWrapper]: expand || !value,
                        [classes.hidden]: hidden
                    }
                )} 
                alignItems="center" 
                justify="space-between"
                onClick={toProgress(order)}
            >
                <Grid item>
                    <Typography
                        variant="displayBlock"
                        className={[
                            clsx(
                                classes.bookingAccordionTitle, 
                                {
                                    [classes.expandTitle]: expand || !value
                                }
                            ),
                            "MuiTypography-h2"
                        ]}
                    >
                        {title}
                    </Typography>
                </Grid>

                <Grid item>
                    {value && <Typography>
                        <Box 
                            component="div" 
                            className={[
                                clsx(
                                    classes.bookingAccordionTitle,
                                    classes.bookingAccordionValue,
                                    {
                                        [classes.expandTitle]: expand || !value
                                    }
                                ),
                                "MuiTypography-h2"
                            ]}
                        >
                            {value}
                        </Box>
                    </Typography>}
                </Grid>
            </Grid>
            <Collapse in={expand} timeout={500}>
                <div>
                    {children}
                    <ProgressControlArea 
                        handleForward={handleForward(order)} 
                        handleBackward={handleBackward(order)}
                        nextText={nextText}
                        backText={backText}
                        forwardAble={forwardAble}
                        helperText={helperText}
                    />
                </div>
            </Collapse>
        </Fragment>
    );
}

export default BookingAccordion;