import { makeStyles } from "@material-ui/core";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    formItem: {
        marginBottom: 30
    },
}));

const BookingFormWrapper = ({children}) => {
    const classes = useStyles();

    return (
        <div className={classes.formItem}>
            {children}
        </div>
    );
}

export default BookingFormWrapper;