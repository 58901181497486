import { ThemeProvider } from "@material-ui/styles";
import {makeStyles} from "@material-ui/core";
import { Route, Switch, BrowserRouter, useParams, Redirect} from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import theme from "./styles/theme";
import StyledSnackBar from "./component/StyledSnackBar";
import useSnackBar from "./hook/useSnackBar";
import { SnackBarContext } from "./context/SnackBarContext";
import routes from "./config/routes";
import { IntlProvider } from "react-intl";
import { useEffect, useState, React } from "react";
import { en, zh_hant, zh } from "./lang/lang";
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";
import {allLangPath, getUrlLang, langToLocaleCode} from './helper/localeConvert';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 60px - 140px - 30px)'
  }
}));

const MainCotent = () => {

  const [messages, setMessages] = useState(en);
  const { lang } = useParams();

  const [locale, setLocale] = useState(langToLocaleCode(lang));

  useEffect(() => {
    localStorage.setItem('locale', locale);
    if (locale == 'zh-hant'){
      document.title = "消費者委員會預約系統";
      setMessages(zh_hant);
    }else if (locale == 'zh'){
      document.title = "消费者委员会预约系统";
      setMessages(zh)
    }else{
      document.title = "Consumer Council Booking System";
      setMessages(en)
    }

  },[locale]);

  useEffect(() => {
    setLocale(langToLocaleCode(lang));
  }, [lang]);

  const classes = useStyles();

  const {open, message, severity, setClose, setMessage, setSuccessMessage, setErrorMessage} = useSnackBar();
  return (
    <IntlProvider messages={messages} locale={locale}>
        <ThemeProvider theme={theme}>
            
              <SnackBarContext.Provider value={{setClose, setMessage, setSuccessMessage, setErrorMessage}}>
              <div>
                <Header lang={locale} setLang={setLocale}/>
                <div className={classes.root}>
                  <Switch>
                    {
                      routes.map((route, index) => {
                        const {path, exact, routes} = route;
                        return (
                          <Route
                            key={index}
                            path={allLangPath(path)}
                            exact={exact}
                            render={props => (
                              <route.component routes={routes} {...props} />
                            )}
                          />
                        );
                      })
                    }
                  </Switch>
                </div>
                <Footer/>
                <StyledSnackBar
                  open={open}
                  autoHideDuration={2000}
                  onClose={setClose}
                  severity={severity}
                  message={message}
                />
              </div>
            </SnackBarContext.Provider>
        </ThemeProvider>
    </IntlProvider>
  );
}

function App() {
  const history = createBrowserHistory();

  ReactGA.initialize("UA-213352864-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });

  

  return (
    <BrowserRouter history={history}>
      <Route path="/:lang">
        {
          (match, pathname) => {
            if (!match.match) {
              return <Redirect to="/en"/>
            }
            return <MainCotent/>
          }
        }
      </Route>     
    </BrowserRouter>
  );
}

export default App;
