import { Grid } from "@material-ui/core";
import { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import DeleteConfirmationDialog from "../../../component/DeleteConfirmationDialog";
import RecordInfoBox from "../../../component/RecordInfoBox";
import useDialog from "../../../hook/useDialog";
import {React} from 'react';
import PageTitle from "../../../component/PageTitle";
import { FormattedMessage } from "react-intl";

const ListingPage = ({location}) => {

    const {
        listOfRecords
    } = location.state;

    const {open, openDialog, closeDialog} = useDialog();

    const [recordToBeCancel, setRecordToBeCancel] = useState(null);

    const handleRecordCancelClick = record => e => {
        setRecordToBeCancel(record);
        openDialog();
    }

    if (!listOfRecords){
        return <Redirect to="/"/>;
    }

    return (
        <Fragment>
            <PageTitle>
                <FormattedMessage id="managepage.entry.title"/>
            </PageTitle>

            <Grid container justify="center">
                <Grid item xs={12}>
                {
                    listOfRecords.length > 0 ? 
                    (listOfRecords || []).map((record, index) => <RecordInfoBox record={record} index={index} onCancel={handleRecordCancelClick(record)}/>)
                    :
                    ""
                }
                </Grid>
            </Grid>

            <DeleteConfirmationDialog
                open={open}
                onClose={closeDialog}
                record={recordToBeCancel}
            />
        </Fragment>
    );
}

export default ListingPage;