export const localeToLangCode = (locale) => locale == 'zh-hant' ? 'tc' : (locale == 'zh' ? 'sc' : 'en');

export const langToLocaleCode = (lang) => lang == 'tc' ? 'zh-hant' : (lang == 'sc' ? 'zh' : 'en');

export const getUrlLang = () => {
    let pathArray = window.location.pathname.split('/');

    if (pathArray[1] == 'en' || pathArray[1] == 'tc' || pathArray[1] == 'sc'){
        return pathArray[1];
    }

    return 'en';
}

export const allLangPath = (path) => {
    return [`/en${path}`, `/tc${path}`, `/sc${path}`];
}