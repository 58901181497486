import { Box, makeStyles, Typography } from "@material-ui/core";
import {React} from 'react';
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 70,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]:{
            height: 50
        }
    },
    filled: {
        backgroundColor: theme.palette.primary.main
    },
    disabled: {
        cursor: 'not-allowed'
    }
}));

const StyledButton = ({
    label,
    filled = false,
    onClick = () => {},
    className,
    type = "button",
    disabled,
    variant = "h3",
    ...rest
}) => {

    const classes = useStyles();

    return(
        <button className={clsx(classes.root, className, {
            [classes.filled]: filled,
            [classes.disabled]: disabled
        })} onClick={onClick} disabled={disabled} {...rest}>
            <Typography variant={variant}>
                {label}
            </Typography>
        </button>
    );
}

export default StyledButton;