import {Container} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SystemTitle from "../../component/SystemTitle";
import {React} from 'react';
import { allLangPath } from "../../helper/localeConvert";

const ManagePage = ({
    routes
}) => {

    const { path, url } = useRouteMatch();

    return (
        <Container>
            <SystemTitle variant="displayInline"><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>

            <Switch>
            {
                routes.map((route, i) => {
                const { path, exact, routes } = route;
                return (
                    <Route
                    key={i}
                    path={allLangPath(path)}
                    exact={exact}
                    render={(routeProps) => (
                        <route.component routes={routes} {...routeProps} />
                    )}
                    />
                );
            })}
            </Switch>
        </Container>
    );
}

export default ManagePage;