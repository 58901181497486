import { Box, Container, Typography, Grid} from "@material-ui/core";
import { Fragment, useCallback, useContext, useState, React, useRef, useEffect} from "react";
import LocationPicker from "../LocationPicker";
import SystemTitle from "../SystemTitle";
import { AccordionContext } from "../../context/AccordionContext";
import SessionPicker from "../SessionPicker";
import { formatTime } from "../../helper/dateTimeFormat";
import BookingForm from "../BookingForm";
import BookingAccordion from "../BookingAccordion";
import useAccordion from "../../hook/useAccordion";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { post } from "../../config/api";
import { useHistory } from "react-router-dom";
import { SnackBarContext } from "../../context/SnackBarContext";
import RedStar from "../RedStar";
import { FormattedMessage, useIntl } from "react-intl";
import PageTitle from "../PageTitle";
import fieldLangConvertor from "../../helper/fieldLangConvertor";
import { getLangCode } from "../../helper/langHelper";
import { localeToLangCode } from "../../helper/localeConvert";

const GeneralBooking = ({
    title,
    purposeId
}) => {

    const {setErrorMessage} = useContext(SnackBarContext);

    const recaptchaRef = useRef();
    const intl = useIntl();

    const progressSubtitle = [
        intl.formatMessage({id: "bookingpage.subtitle1"}),
        intl.formatMessage({id: "bookingpage.subtitle2"}),
        intl.formatMessage({id: "bookingpage.subtitle3"})
    ];

    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            name: "",
            primary_contact_type_id: "1",
            email: "",
            mobile: "",
            session_id: "",
            subscription_code: "",
            prefer_lang: intl.locale == 'zh' ? "3" : (intl.locale == 'zh-hant' ? "2" : "1"),
            salutation: "1",
            texting_lang: getLangCode(intl.locale),
            agreement: false,
            otpVerified: false,
            captcha: false
        },
        validationSchema: Yup.object({
            name: Yup.string().required(intl.formatMessage({id: "form.required"})),
            agreement: Yup.bool().oneOf([true], intl.formatMessage({id: "form.agreement.required"})),
            otpVerified: Yup.bool().oneOf([true], intl.formatMessage({id: "form.otpVerified.required"})),
            captcha: Yup.bool().oneOf([true], intl.formatMessage({id: "form.captcha.required"})),
            captcha_sign: Yup.string().required(intl.formatMessage({id: "form.captcha.sign.required"})),
            email: Yup.string().email(intl.formatMessage({id: "form.email.validate"})).nullable(),
            mobile: Yup.string().max(8, intl.formatMessage({id: "form.mobile.length.validate"})).min(8, intl.formatMessage({id: "form.mobile.length.validate"})).nullable()
        }),
        onSubmit: values => {
            post('/bookingRecord', values)
            .then(res => {
                history.push(`/${localeToLangCode(intl.locale)}/thankyou`, res);
            })
            .catch(err => {
                const statusCode = err.response.data.status_code;
                const msgCode = err.response.data.msg_code;
                if (statusCode == 0){
                    if (msgCode){
                        setErrorMessage(intl.formatMessage({id: `error.msg.${msgCode}`}));
                    }else{
                        setErrorMessage(err.response.data.msg);
                    }         
                }
                recaptchaRef.current.reset();
            })
        }
    });

    useEffect(() => {
        formik.validateForm();
    }, [intl]);

    const [branch, setBranch] = useState(null);
    const {progress, setProgress, handleForward, handleBackward, toProgress} = useAccordion({
        totalNumOfAccordion: 3,
        onSubmit: formik.handleSubmit
    });
    const [selectedSession, setSelectedSession] = useState(null);

    const handleSessionSelected = (session) => {
        setSelectedSession(session);
        formik.setFieldValue("session_id", session.id);
    }

    const handleBranchChange = useCallback((branch) => {
        setBranch(branch);
        setSelectedSession(null);
    }, []);

    const validateOTPToken = useCallback(async (id, token) => {

        const data = {
            idetifier: id,
            token: token
        };

        try{
            const res = await post('/otp/validate', data);

            if (res.status){
                formik.setFieldValue("otpVerified", true);
            }else{
                formik.setFieldValue("otpVerified", false);
                setErrorMessage(intl.formatMessage({id: 'otp.validation.error'}));
            }
        } catch (e) {
            setErrorMessage(intl.formatMessage({id: 'otp.validation.error'}));
        }
    }, [formik]);

    useEffect(() => {
        formik.setFieldValue('texting_lang', getLangCode(intl.locale));
    }, [intl.locale]);
    
    return (
        <Fragment>
            <Container>
                <SystemTitle variant="displayInline"><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>
                
                <PageTitle variant="h1">
                    {title}
                </PageTitle>

                <Typography variant="displayBlock" className="MuiTypography-h2">
                    {progressSubtitle[progress]}
                </Typography>

                <AccordionContext.Provider value={{progress, setProgress, handleForward, handleBackward, toProgress}}>
                    <Grid container justify="center" spacing={1}>        
                        <Grid item xs={12}>
                            <BookingAccordion 
                                title={`1. ${intl.formatMessage({id: "bookingpage.accordion.title1"})}`}
                                value={branch ? fieldLangConvertor(branch, 'branch_name', intl.locale) : null}
                                backText={intl.formatMessage({id: "bookingpage.accordion.back1"})}
                                nextText={intl.formatMessage({id: "bookingpage.accordion.next1"})}
                                order={0}
                                forwardAble={branch}
                            >
                                <LocationPicker value={branch} onChange={handleBranchChange} purposeId={purposeId}/>
                            </BookingAccordion>
                        </Grid>

                        <Grid item xs={12}>
                            <BookingAccordion 
                                title={`2. ${intl.formatMessage({id: "bookingpage.accordion.title2"})}`}
                                value={selectedSession ? `${selectedSession.date} ${formatTime(selectedSession.start_time)}` : null}
                                order={1}
                                backText={intl.formatMessage({id: "bookingpage.accordion.back2"})}
                                nextText={intl.formatMessage({id: "bookingpage.accordion.next2"})}
                                forwardAble={selectedSession ? true : false}
                            >
                                <SessionPicker
                                    branchId={branch ? branch.id : null}
                                    purposeId={purposeId}
                                    value={selectedSession}
                                    onChange={handleSessionSelected}
                                />
                            </BookingAccordion>
                        </Grid>

                        <Grid item xs={12}>
                            <BookingAccordion 
                                title={`3. ${intl.formatMessage({id: "bookingpage.accordion.title3"})}`}
                                order={2}
                                backText={intl.formatMessage({id: "bookingpage.accordion.back3"})}
                                nextText={intl.formatMessage({id: "bookingpage.accordion.next3"})}
                                helperText={(
                                    <Typography>
                                        <Box color='#B30900'>
                                            <RedStar/> <FormattedMessage id="form.necessary"/>
                                        </Box>
                                    </Typography>
                                )}
                            >
                                <BookingForm
                                    formik={formik}
                                    validateOTPToken={validateOTPToken}
                                    purposeId={purposeId}
                                    recaptchaRef={recaptchaRef}
                                />
                            </BookingAccordion>
                        </Grid>
                    </Grid>
                </AccordionContext.Provider>
            </Container>
        </Fragment>
    );
}

export default GeneralBooking;