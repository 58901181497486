export const en = {
    "booking.detail.gift.remark": "Please arrive on time and bring along with the redemption letter or email for gift redemption.",
    "sitemap.title": "Sitemap",
    "sitemap.home": "Home",
    "sitemap.latest.news": "Latest News",
    "sitemap.enquiry.complaint": "Enquiry / Complaint",
    "sitemap.gift.redemption": "Gift Redemption",
    "sitemap.manage.confirmed.bookings": "Manage Confiremd Bookings",
    "sitemap.copyright.disclaimer": "Copyright & Disclaimer",
    "sitemap.code.on.access.to.information": "Code on Accesss to Information",
    "sitemap.privacy": "Privacy",
    "sitemap.accessibility.statement": "Accessibility Statement",
    "booking.detail.enquiry.remark": "Please arrive on time and bring along with the documents/receipts/contracts to ensure an effective follow-up.",
    "complain.dialog.content.last": "<u>I understand and confirm that the enquiries / complaints to be made fall within the scope of the Consumer Council.</u>",
    "statement.title": "Personal Information Collection Statement",
    "statement.content.part1": "The personal data provided in the complaint form will be used by the Council for one or more of the purposes specified below:",
    "statement.content.part2": "To consider, respond to, and take follow up actions in respect of your complaint;",
    "statement.content.part3": "To conduct market studies into matters concerning consumer interests in Hong Kong and/or for other research and statistical purposes",
    "statement.content.part4": "The Council may transfer the information and personal data supplied by you to parties who will be involved in handling the complaint, including the trader being complained against, relevant Government Departments, related authorities and organizations, as well as other agencies which are authorized to receive information relating to law enforcement, prosecution and review of decisions.",
    "statement.content.part5": "You have the right to request access to and correction of your personal data submitted to the Council. Should you wish to do so, please make your request in writing to the Council, with the complaint case number quoted (if applicable). A fee may be charged if you request the Council to supply copies of such personal data.",
    "complain.dialog.accept": "Confirm",
    "complain.dialog.back": "Back",
    "complain.dialog.title": "",
    "complain.dialog.content.title": "The kinds of complaints which the Council handles:",
    "complain.dialog.content.para1": "Complaints on goods and services purchased from traders by individual consumers <u>for private use or consumption</u>.",
    "complain.dialog.content.para2": "Complaints of the following nature would probably fall outside the Council’s scope of work:",
    "complain.dialog.content.para2.point1": "Complaints involving goods and services purchased for use in the operation of business or in the name of a business entity;",
    "complain.dialog.content.para2.point2": "Private transaction between individuals not in the course of business;",
    "complain.dialog.content.para2.point3": "Complaints involving goods and services supplied by the Government;",
    "complain.dialog.content.para2.point4": "Complaints arising solely from the performance of an investment;",
    "complain.dialog.content.para2.point5": "Complaints unrelated to consumer issues, such as those regarding gambling, auction.",
    "form.email.validate": "Pleas enter a valid email",
    "otp.reminder": 'Please enter OTP in 5 minutes, Otherwise, click "Get OTP" again for new one-time password',
    "error.msg.0001": "You can only book 1 session with same purpose in each 7 days",
    "error.msg.0002": "Session is full of booking",
    "otp.validation.error": "Wrong Otp Code",
    "form.otp.mobile.required": "Please Enter Mobile Phone",
    "form.otp.email.required": "Please Enter Email",
    "form.required": "Required",
    "form.agreement.required": "Acceptance is required",
    "form.otpVerified.required": "Email / Moblie not verified",
    "form.captcha.required": "Please verify recaptcha",
    "form.mobile.length.validate": "Please enter valid mobile number",
    "latest.news.update.at": "Update at:",
    "form.salutation": "Salutation",
    "salutation.label.mr": "Mr.",
    "salutation.label.mrs": "Mrs.",
    "salutation.label.miss": "Miss.",
    "copy.right.page.title": "Copyright and Disclaimer",
    "copy.right.content1.title": "Copyright", 
    "copy.right.content2.title": "Prohibition against advertisement purposes", 
    "copy.right.content3.title": "Disclaimer",
    "copy.right.content1.text1": "The Consumer Council (Council) retains all rights, title and interest (including copyright) in all the content of this website.",  
    "copy.right.content1.text2": "The content of this website can be used or reproduced accurately and without any amendment in any form for personal non-commercial purpose only.",
    "copy.right.content1.text3": "No part of the content of this website may be reproduced, copied, distributed, or otherwise used in any form for any commercial purpose without the prior written consent of the Council.",
    "copy.right.content1.text4": "Application for any use, other than personal non-commercial use permitted above, of any part of the content of this website should be made to the Council.",
    "copy.right.content2.text1": "Section 20 of the Consumer Council Ordinance provides, among others, that unless with the Council’s written consent, no person shall, with a view to promoting or disparaging any goods, services or immovable property or promoting any person’s image, publish any advertisement which either expressly or by implication makes reference to the Council, its publication or findings of tests or surveys conducted by or on behalf of the Council.",  
    "copy.right.content2.text2": "The full text of Section 20 is as follows:",
    "copy.right.content2.text2.text1": "No person shall, with a view to promoting or disparaging any goods, services or immovable property or promoting the image of any person, publish or cause to be published any advertisement which either expressly or by implication makes reference to –",
    "copy.right.content2.text2.text1.text1": "the Council;",
    "copy.right.content2.text2.text1.text2": "any committee, member, agent or employee of the Council;",
    "copy.right.content2.text2.text1.text3": "the Council’s publication or finding of tests or surveys conducted by or on behalf of the Council; or",
    "copy.right.content2.text2.text1.text4": "any other information published by the Council, without written consent of the Council.",
    "copy.right.content2.text2.text2": "Any person who contravenes subsection (1) commits an offence and is liable to a fine of $100000.",
    "copy.right.content2.text2.text3": "For the purpose of subsection (1) “advertisement” (廣告) may be constituted by any words, whether written or spoken, or any picture, drawing, visual image, figure or article –",
    "copy.right.content2.text2.text3.text1": "appearing in any publication; or",
    "copy.right.content2.text2.text3.text2": "brought to the notice of the public or any section of the public in any other way.",
    "copy.right.content3.text1": "The content of this website is provided for general information only and does not constitute any legal advice. Users should seek legal advice from their own lawyers when appropriate.",  
    "copy.right.content3.text2": "While every effort is made to update this website from time to time and to ensure its accuracy, no express or implied warranty is given by the Council as to the timeliness, accuracy or completeness of any content of this website.", 
    "copy.right.content3.text3": "The use of this website is at the user’s own risk and the Council does not guarantee that access to this website is completely safe and protected from viruses. The Council is not liable for any damage or loss suffered (including damage to the user’s system or loss of data) as a result of use of this website.", 
    "copy.right.content3.text4": "Provision of, or assistance in providing, materials contributed by third parties on this website or links to external websites gives rise to no statement, representation or warranty, express or implied, that the Council agrees or does not disagree with the contents of any such materials or external websites and the Council will not have or accept any liability, obligation or responsibility whatsoever for any loss, destruction or damage (including without limitation consequent loss, destruction or damage) however arising from or in respect of any use or misuse of or reliance on the contents of any such materials or external websites delivered on or via this website or inability to use any of them.", 
    "copy.right.content3.text5": "This Disclaimer has been translated into Chinese. If there is any inconsistency or ambiguity between the English version and the Chinese version, the English version shall prevail.", 
    "copy.right.content3.text6": "By visiting this website, you agree to accept unconditionally the terms of this Disclaimer and as they may be revised and/or amended from time to time by the Council without prior notice to you. Please check this page regularly for any revisions and/or amendments which may be made.", 
    "otp.get.the": "Get the OTP",
    "session.picker.available.date": "Available date",
    "session.picker.available.timeslot": "Available timeslot",
    "booking.cancel.dialog.cancel": "Confirm",
    "booking.cancel.dialog.close": "Close",
    "booking.cancel.dialog.title": "Cancel Confirmation",
    "booking.detail.confirm.title": "Booking Confirmation",
    "booking.detail.email": "Email address",
    "booking.detail.email.sent": "Details have been sent to",
    "booking.detail.mobile.sent": "Details have been sent to",
    "booking.detail.mobile": "Mobile",
    "booking.detail.ref.code": "Reference number",
    "booking.detail.updated.title": "Your bookings has been updated",
    "bookingpage.accordion.back1": "Back to home",
    "bookingpage.accordion.back2": "Back",
    "bookingpage.accordion.back3": "Back",
    "bookingpage.accordion.next1": "Next",
    "bookingpage.accordion.next2": "Next",
    "bookingpage.accordion.next3": "Confirm",
    "bookingpage.accordion.title1": "Choose Location",
    "bookingpage.accordion.title2": "Choose Date & Time",
    "bookingpage.accordion.title3": "Fill in your profile",
    "bookingpage.subtitle1": "Please choose location for you appointment:",
    "bookingpage.subtitle2": "Please choose the date and time for your booking:",
    "bookingpage.subtitle3": "Please submit the required information for our follow up:",
    "cancelpage.back": "Back to Home",
    "cancelpage.title": "You have successfully cancelled the booking",
    "complaintpage.title": "Enquiry / Complaint",
    "confirmpage.title": "Booking Confirmation",
    "footer.accessibilityStatement": "Accessibility Statement",
    "footer.codeOnAccess": "Code on Access to Information",
    "footer.copyright": "Copyright & Disclaimer",
    "footer.privacy": "Privacy",
    "footer.reservedStatement": "Copyright © 2021 Consumer Council. All rights reserved.",
    "footer.sitemap": "Sitemap",
    "form.agree.to": "I agree to the ",
    "form.email": "Email address",
    "form.enter.otp": "Enter One-Time Password (OTP)",
    "form.get.otp": "Get the OTP",
    "form.mobile": "Mobile number (Only applicable to Hong Kong local mobile phone numbers)",
    "form.name": "Full Name",
    "form.necessary": "Necessary",
    "form.optional": "(Optional)",
    "form.personal.statement": "Personal Information Collection Statement",
    "form.preferred.lang": "Preferred Communication Language",
    "form.primary.contact": "Primary Contact",
    "form.second.contact": "Secondary Contact",
    "form.sub.code": "Subscription Code / Email(Optional)",
    "form.verified": "Verified",
    "form.verify": "Verify",
    "giftpage.title": "Gift Redemption",
    "header.langSwitcher.firstLang": "繁",
    "header.langSwitcher.secondLang": "简",
    "homepage.enquiry.content": "Make an appointment to enquire or complain in our centre",
    "homepage.enquiry.title": "Enquiry / Complaint",
    "homepage.gift.content": "Subscriber can make an appointment to redeem the gifts upon receipt of the “Redemption Letter”",
    "homepage.gift.title": "Gift Redemption",
    "homepage.manage.content": "Check your bookings or update/cancel them at least 3 hours in advance",
    "homepage.manage.title": "Manage Confirmed Bookings",
    "homepage.title": "Hello, please select service",
    "managepage.entry.back": "Back",
    "managepage.entry.next": "View the booking",
    "managepage.entry.subtitle": "Please fill in the following information to verify your identity",
    "managepage.entry.title": "Manage my bookings",
    "managepage.listing.booking": "Booking",
    "managepage.listing.cancel": "Cancel the booking",
    "managepage.listing.datetime": "Date & Time",
    "managepage.listing.location": "Centre Location",
    "managepage.listing.ref": "Reference Number",
    "managepage.listing.service": "Service",
    "managepage.listing.update": "Update the booking",
    "newspage.back": "Back to Home",
    "newspage.date": "Date",
    "newspage.news": "News",
    "newspage.title": "Latest News",
    "notification.latestNews": "Latest news",
    "preferred.language.label.eng": "English",
    "preferred.language.label.zhas": "Putonghua",
    "preferred.language.label.zhat": "Cantonese",
    "sessionpicker.avaliabletime": "Available time",
    "sessionpicker.reminder": "Available timeslots for the next 14 days",
    "system.headline1": "Consumer Council",
    "system.headline2": "Booking System"
};

export const zh_hant = {
    "booking.detail.gift.remark": "請準時到達及帶同換領禮品通知書或電郵以領取禮品。",
    "sitemap.title": "網站地圖",
    "sitemap.home": "主頁",
    "sitemap.latest.news": "最新消息",
    "sitemap.enquiry.complaint": "諮詢或投訴",
    "sitemap.gift.redemption": "禮品兌換",
    "sitemap.manage.confirmed.bookings": "管理已確認的預約",
    "sitemap.copyright.disclaimer": "版權和免責聲明",
    "sitemap.code.on.access.to.information": "公開資料守則",
    "sitemap.privacy": "私隱政策",
    "sitemap.accessibility.statement": "無障礙聲明",
    "booking.detail.enquiry.remark": "請準時到達及攜同文件/單據/合約以確保更有效處理 閣下之諮詢/投訴。",
    "complain.dialog.content.last": "本人明白及確認是次預約諮詢/投訴屬消費者委員會處理的投訴類型。",
    "statement.title": "收集個人資料聲明",
    "statement.content.part1": "閣下所提供的個人資料，會供消費者委員會作下列一項或多項用途：",
    "statement.content.part2": "審視、回應及跟進 閣下的投訴；",
    "statement.content.part3": "就促進本港消費者權益事宜，進行市場研究、考察報告及統計分析",
    "statement.content.part4": "本會可能會將 閣下提供的個人資料及文件轉交給處理投訴的相關人士，包括被投訴的商號、政府部門及其他有關機構，亦可能會披露予獲授權收取與執法、起訴及覆檢決定的機構。",
    "statement.content.part5": "閣下有權查閱及更正所提供的個人資料。查閱或更正資料要求應以書面向本會提出，請註明個案編號(如適用) 。本會可能酌情就複印資料收取行政費用。",
    "complain.dialog.accept": "確認",
    "complain.dialog.back": "返回",
    "complain.dialog.title": "",
    "complain.dialog.content.title": "消費者委員會處理的投訴類型：",
    "complain.dialog.content.para1": "消費者向商戶購買貨品或服務作<u>私人使用或耗用</u>時遇上消費糾紛，可向本會作出投訴。",
    "complain.dialog.content.para2": "若投訴涉及下列情況，可能已超出本會的工作範疇：",
    "complain.dialog.content.para2.point1": "所購買貨品或服務是用於商業或業務運作中，或以公司名義購入；",
    "complain.dialog.content.para2.point2": "並不關乎商業或業務運作的私人買賣交易；",
    "complain.dialog.content.para2.point3": "涉及由政府提供的貨品或服務；",
    "complain.dialog.content.para2.point4": "投訴純粹關乎某項投資的表現；",
    "complain.dialog.content.para2.point5": "個案不屬消費事宜，例如與博彩、拍賣有關的活動",
    "form.email.validate": "請輸入正確電子郵件",
    "otp.reminder": '請在5分鐘內輸入驗證碼，否則點擊“獲取驗證碼”獲取新代碼',
    "error.msg.0001": "每7日只能預約一次",
    "error.msg.0002": "時段已滿",
    "otp.validation.error": "驗證碼錯誤",
    "form.otp.mobile.required": "請輸入手機號碼",
    "form.otp.email.required": "請輸入電子郵件",
    "form.required": "必填",
    "form.agreement.required": "必須接受條款",
    "form.otpVerified.required": "電子郵件 / 手機號碼尚未認證",
    "form.captcha.required": "請驗證 recaptcha",
    "form.mobile.length.validate": "請輸入正確手機號碼",
    "latest.news.update.at": "更新於",
    "form.salutation": "稱呼",
    "salutation.label.mr": "先生",
    "salutation.label.mrs": "女士",
    "salutation.label.miss": "小姐",
    "copy.right.page.title": "版權和免責聲明",
    "copy.right.content1.title": "版權", 
    "copy.right.content2.title": "禁作廣告用途", 
    "copy.right.content3.title": "免責聲明",
    "copy.right.content1.text1": "消費者委員會（消委會）保留所有就此網站內的資料的權利、擁有權及權益（包括版權）。",  
    "copy.right.content1.text2": "此網站內的資料只供個人非商業用途，而且複製的資料必須準確及不能作任何修改。",
    "copy.right.content1.text3": "除非得到消委會的事先書面同意，不可複製、抄寫、發布或以任何其他形式使用此網站內的任何資料作任何商業用途。",
    "copy.right.content1.text4": "若要使用此網站內的資料作任何上述准許用途以外的用途，請向消委會提出申請。",
    "copy.right.content2.text1": "《消費者委員會條例》第二十條第(1)款規定（除其他事項外），任何人未經消委會以書面同意，不得發布或安排發布任何廣告，以明示或默示的方式提述消委會、消委會的刊物、消委會或委任他人進行的測試或調查的結果，藉以宣傳或貶損任何貨品、服務或不動產，或推廣任何人的形象。",  
    "copy.right.content2.text2": "第二十條全文如下：",
    "copy.right.content2.text2.text1": "任何人未經委員會以書面同意，不得發布或安排發布任何廣告，以明示或默示的方式提述—",
    "copy.right.content2.text2.text1.text1": "委員會；",
    "copy.right.content2.text2.text1.text2": "委員會屬下的任何小組、委員會的委員、代理人或僱員；",
    "copy.right.content2.text2.text1.text3": "委員會的刊物或委員會所作出或由他人代其作出的測試或調查所得的 結果；或",
    "copy.right.content2.text2.text1.text4": "委員會所發布的任何其他資料藉以宣傳或貶損任何貨品、服務或不動產，或宣傳任何人的形象。",
    "copy.right.content2.text2.text2": "任何人違反第(1)款的規定，即屬犯罪，可處罰款$100000。",
    "copy.right.content2.text2.text3": "就第(1)款而言，“廣告”(advertisement)可由—",
    "copy.right.content2.text2.text3.text1": "在任何刊物中出現的任何文字、說話、圖片、繪圖、視覺影像、圖形或物品構成；或",
    "copy.right.content2.text2.text3.text2": "以任何其他方式使公眾或部分公眾注意的任何文字、說話、圖片、繪圖、視覺影像、圖形或物品構成。",
    "copy.right.content3.text1": "此網站內容是提供一般資料作參考，並不構成任何法律意見，使用者應按需要向自己的律師尋求法律意見。",  
    "copy.right.content3.text2": "消委會雖然盡力不時更新此網站及確保其準確性，但並不就此網站任何內容的及時性、準確性或完整性作出任何明示或隱含的保證。", 
    "copy.right.content3.text3": "此網站的使用者須要自負風險，消委會並不保證進入此網站是絕對安全及有電腦病毒防護，亦不會就因使用此網站而引致的任何損失（包括使用者系統損壞或資料損失）承擔任何法律責任。", 
    "copy.right.content3.text4": "在此網站提供或協助提供源自第三者的資料或外界網站的連結，並不構成消委會就贊同或沒有不贊同該等資料或外界網站的內容作出任何明示或默示的聲明、陳述或保證：對任何因使用或不當使用或依據此網站提供或經此網站提供的資料或外界網站的內容或未能使用該等內容而引致或所涉及的損失，毀壞或損失（包括但不限於相應而生的損失、毀壞或損害），消委會概不承擔任何義務、責任或法律責任。", 
    "copy.right.content3.text5": "此中文免責聲明為英文版本譯本。如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。", 
    "copy.right.content3.text6": "你瀏覽此網站，即表示你同意無條件接受本免責聲明的條款，以及消委會不時在沒有預先通知的情況下對上述條款作出的修改及/或修訂。請定期瀏覽此網頁查閱任何修改及/或修訂。", 
    "otp.get.the": "獲取驗證碼",
    "session.picker.available.date": "可供預約的日期",
    "session.picker.available.timeslot": "可供預約的時間",
    "booking.cancel.dialog.cancel": "確認",
    "booking.cancel.dialog.close": "關閉",
    "booking.cancel.dialog.title": "取消確認",
    "booking.detail.confirm.title": "你已成功預約",
    "booking.detail.email": "電子郵件",
    "booking.detail.email.sent": "確認電郵已傳送至",
    "booking.detail.mobile.sent": "確認短訊已傳送至",
    "booking.detail.mobile": "手機",
    "booking.detail.ref.code": "參考號碼",
    "booking.detail.updated.title": "你已成功更新預約",
    "bookingpage.accordion.back1": "返回主頁",
    "bookingpage.accordion.back2": "上一步",
    "bookingpage.accordion.back3": "上一步",
    "bookingpage.accordion.next1": "下一步",
    "bookingpage.accordion.next2": "下一步",
    "bookingpage.accordion.next3": "確認",
    "bookingpage.accordion.title1": "選擇地點",
    "bookingpage.accordion.title2": "日期及時間",
    "bookingpage.accordion.title3": "填寫個人資料",
    "bookingpage.subtitle1": "請選擇預約地點：",
    "bookingpage.subtitle2": "請選擇預約日期及時間：",
    "bookingpage.subtitle3": "請提供完整資料，以便跟進。",
    "cancelpage.back": "返回主頁",
    "cancelpage.title": "你已取消預約",
    "complaintpage.title": "諮詢 / 投訴",
    "confirmpage.title": "你已成功預約",
    "footer.accessibilityStatement": "無障礙聲明",
    "footer.codeOnAccess": "公開資料守則",
    "footer.copyright": "版權和免責聲明",
    "footer.privacy": "私隱政策",
    "footer.reservedStatement": "版權所有 © 2021 消費者委員會，並保留一切權利。",
    "footer.sitemap": "網頁地圖",
    "form.agree.to": "本人同意",
    "form.email": "電郵",
    "form.enter.otp": "輸入一次性密碼",
    "form.get.otp": "獲取一次性密碼",
    "form.mobile": "手機號碼（只適用於香港本地手提電話號碼）",
    "form.name": "姓名",
    "form.necessary": "必填",
    "form.optional": "（選填）",
    "form.personal.statement": "收集個人資料聲明",
    "form.preferred.lang": "面談時選用的溝通語言",
    "form.primary.contact": "主要聯絡方法",
    "form.second.contact": "次要聯絡方法",
    "form.sub.code": "訂閱編號 / 電子郵件（如你有多個訂閱編號，只需以其中一個作登記）",
    "form.verified": "已驗證",
    "form.verify": "驗證",
    "giftpage.title": "換領禮品",
    "header.langSwitcher.firstLang": "EN",
    "header.langSwitcher.secondLang": "简",
    "homepage.enquiry.content": "預約時間親臨指定地點諮詢意見或處理投訴事宜",
    "homepage.enquiry.title": "諮詢/投訴",
    "homepage.gift.content": "訂戶收到「換領禮品通知書」後，可預約時間親臨指定地點換領禮品",
    "homepage.gift.title": "換領禮品",
    "homepage.manage.content": "查看預約／更改時間或取消預約（最遲於預約時間3小時前）",
    "homepage.manage.title": "管理預約",
    "homepage.title": "您好，請選擇預約服務：",
    "managepage.entry.back": "返回主頁",
    "managepage.entry.next": "查看預約",
    "managepage.entry.subtitle": "請提供完整資料，以便核實身分",
    "managepage.entry.title": "管理預約",
    "managepage.listing.booking": "預約",
    "managepage.listing.cancel": "取消預約",
    "managepage.listing.datetime": "預約日期及時間",
    "managepage.listing.location": "預約地點",
    "managepage.listing.ref": "參考號碼",
    "managepage.listing.service": "預約服務",
    "managepage.listing.update": "更改預約",
    "newspage.back": "返回主頁",
    "newspage.date": "日期",
    "newspage.news": "標題",
    "newspage.title": "最新消息",
    "notification.latestNews": "最新消息",
    "preferred.language.label.eng": "英文",
    "preferred.language.label.zhas": "普通話",
    "preferred.language.label.zhat": "廣東話",
    "sessionpicker.avaliabletime": "可供預約的時間",
    "sessionpicker.reminder": "未來14天可供預約的日期及時間",
    "system.headline1": "消費者委員會",
    "system.headline2": "預約系統"
};

export const zh = {
    "booking.detail.gift.remark": "请准时到达及带同换领礼品通知书或电邮以领取礼品。",
    "sitemap.title": "网站地图",
    "sitemap.home": "主页",
    "sitemap.latest.news": "最新消息",
    "sitemap.enquiry.complaint": "谘询或投诉",
    "sitemap.gift.redemption": "礼品兑换",
    "sitemap.manage.confirmed.bookings": "管理已确认的预约",
    "sitemap.copyright.disclaimer": "版权和免责声明",
    "sitemap.code.on.access.to.information": "公开资料守则",
    "sitemap.privacy": "私隐政策",
    "sitemap.accessibility.statement": "无障碍声明",
    "booking.detail.enquiry.remark": "请准时到达及携同文件/单据/合约以确保更有效处理 阁下之咨询/投诉。",
    "complain.dialog.content.last": "本人明白及确认是次预约咨询/投诉属消费者委员会处理的投诉类型。",
    "statement.title": "收集个人资料声明",
    "statement.content.part1": "阁下所提供的个人资料，会供消费者委员会作下列一项或多项用途：",
    "statement.content.part2": "审视、回应及跟进 阁下的投诉；",
    "statement.content.part3": "就促进本港消费者权益事宜，进行市场研究、考察报告及统计分析",
    "statement.content.part4": "本会可能会将 阁下提供的个人资料及文件转交给处理投诉的相关人士，包括被投诉的商号、政府部门及其他有关机构，亦可能会披露予获授权收取与执法、起诉及覆检决定的机构。",
    "statement.content.part5": "阁下有权查阅及更正所提供的个人资料。查阅或更正资料要求应以书面向本会提出，请注明个案编号(如适用) 。本会可能酌情就复印资料收取行政费用。",
    "complain.dialog.accept": "确认",
    "complain.dialog.back": "返回",
    "complain.dialog.title": "",
    "complain.dialog.content.title": "消费者向商户购买货品或服务作私人使用或耗用时遇上消费纠纷，可向本会作出投诉。",
    "complain.dialog.content.para1": "消费者向商户购买货品或服务作<u>私人使用或耗用</u>时遇上消费纠纷，可向本会作出投诉。",
    "complain.dialog.content.para2": "若投诉涉及下列情况，可能已超出本会的工作范畴：",
    "complain.dialog.content.para2.point1": "所购买货品或服务是用于商业或业务运作中，或以公司名义购入；",
    "complain.dialog.content.para2.point2": "并不关乎商业或业务运作的私人买卖交易；",
    "complain.dialog.content.para2.point3": "涉及由政府提供的货品或服务；",
    "complain.dialog.content.para2.point4": "投诉纯粹关乎某项投资的表现；",
    "complain.dialog.content.para2.point5": "个案不属消费事宜，例如与博彩、拍卖有关的活动",
    "form.email.validate": "请输入正确电子邮件",
    "otp.reminder": '请在5分钟内输入验证码，否则点击“获取验证码”获取新代码',
    "error.msg.0001": "每7日只能预约一次", 
    "error.msg.0002": "时段已满",
    "otp.validation.error": "验证码错误",
    "form.otp.mobile.required": "请输入手机号码",
    "form.otp.email.required": "请输入电子邮件",
    "form.required": "必填",
    "form.agreement.required": "必须接受条款",
    "form.otpVerified.required": "电子邮件 / 手机号码尚未认证",
    "form.captcha.required": "请验证 recaptcha",
    "form.mobile.length.validate": "请输入正确手机号码",
    "latest.news.update.at": "更新于",
    "form.salutation": "称呼",
    "salutation.label.mr": "先生",
    "salutation.label.mrs": "女士",
    "salutation.label.miss": "小姐",
    "otp.get.the": "获取验证码",
    "session.picker.available.date": "可供预约的日期",
    "session.picker.available.timeslot": "可供预约的时间",
    "booking.cancel.dialog.cancel": "确认",
    "booking.cancel.dialog.close": "关闭",
    "booking.cancel.dialog.title": "取消确认",
    "booking.detail.confirm.title": "你已成功预约",
    "booking.detail.email": "电子邮件",
    "booking.detail.email.sent": "确认电邮已传送至",
    "booking.detail.mobile.sent": "确认短讯已传送至",
    "booking.detail.mobile": "手机",
    "booking.detail.ref.code": "参考号码",
    "booking.detail.updated.title": "你已成功更新预约",
    "bookingpage.accordion.back1": "返回主页",
    "bookingpage.accordion.back2": "上一步",
    "bookingpage.accordion.back3": "上一步",
    "bookingpage.accordion.next1": "下一步",
    "bookingpage.accordion.next2": "下一步",
    "bookingpage.accordion.next3": "确认",
    "bookingpage.accordion.title1": "选择地点",
    "bookingpage.accordion.title2": "日期及时间",
    "bookingpage.accordion.title3": "填写个人资料",
    "bookingpage.subtitle1": "请选择预约地点：",
    "bookingpage.subtitle2": "请选择预约日期及时间：",
    "bookingpage.subtitle3": "请提供完整资料，以便跟进。",
    "cancelpage.back": "返回主页",
    "cancelpage.title": "你已取消预约",
    "complaintpage.title": "谘询 / 投诉",
    "confirmpage.title": "你已成功预约",
    "footer.accessibilityStatement": "无障碍声明",
    "footer.codeOnAccess": "公开资料守则",
    "footer.copyright": "版权和免责声明",
    "footer.privacy": "私隐政策",
    "footer.reservedStatement": "版权所有 © 2021 消费者委员会，并保留一切权利。",
    "footer.sitemap": "网页地图",
    "form.agree.to": "本人同意",
    "form.email": "电邮",
    "form.enter.otp": "输入一次性密码",
    "form.get.otp": "获取一次性密码",
    "form.mobile": "手机号码（只适用于香港本地手提电话号码）",
    "form.name": "姓名",
    "form.necessary": "必填",
    "form.optional": "（选填）",
    "form.personal.statement": "收集个人资料声明",
    "form.preferred.lang": "面谈时选用的沟通语言",
    "form.primary.contact": "主要联络方法",
    "form.second.contact": "次要联络方法",
    "form.sub.code": "订阅编号 / 电子邮件（如你有多个订阅编号，只需以其中一个作登记）",
    "form.verified": "已验证",
    "form.verify": "验证",
    "giftpage.title": "换领礼品",
    "header.langSwitcher.firstLang": "EN",
    "header.langSwitcher.secondLang": "繁",
    "homepage.enquiry.content": "预约时间亲临指定地点谘询意见或处理投诉事宜",
    "homepage.enquiry.title": "谘询/投诉",
    "homepage.gift.content": "订户收到「换领礼品通知书」后，可预约时间亲临指定地点换领礼品",
    "homepage.gift.title": "换领礼品",
    "homepage.manage.content": "查看预约／更改时间或取消预约（最迟于预约时间3小时前）",
    "homepage.manage.title": "管理预约",
    "homepage.title": "您好，请选择预约服务：",
    "managepage.entry.back": "返回主页",
    "managepage.entry.next": "查看预约",
    "managepage.entry.subtitle": "请提供完整资料，以便核实身分",
    "managepage.entry.title": "管理预约",
    "managepage.listing.booking": "预约",
    "managepage.listing.cancel": "取消预约",
    "managepage.listing.datetime": "预约日期及时间",
    "managepage.listing.location": "预约地点",
    "managepage.listing.ref": "参考号码",
    "managepage.listing.service": "预约服务",
    "managepage.listing.update": "更改预约",
    "newspage.back": "返回主页",
    "newspage.date": "日期",
    "newspage.news": "标题",
    "newspage.title": "最新消息",
    "notification.latestNews": "最新消息",
    "preferred.language.label.eng": "英文",
    "preferred.language.label.zhas": "普通话",
    "preferred.language.label.zhat": "广东话",
    "sessionpicker.avaliabletime": "可供预约的时间",
    "sessionpicker.reminder": "未来14天可供预约的日期及时间",
    "system.headline1": "消费者委员会",
    "system.headline2": "预约系统"
};