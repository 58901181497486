import { Box, makeStyles, Typography } from "@material-ui/core";
import RedStar from "../RedStar";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    formLabel: {
        margin: '0 0 10px 0',
        fontSize: 24,
        fontWeight: 100,
        color: '#666666'
    }
}));


const BookingFormLabel = ({
    label, 
    required = false,
}) => {
    const classes = useStyles();

    return (
        <Typography component="p" className={classes.formLabel}>
            {label}
            {
                required && <RedStar/>
            }
        </Typography>
    );
}

export default BookingFormLabel;