import { makeStyles } from "@material-ui/core";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    root:{
        color: '#B30900',
        fontWeight: 'bold',
        marginLeft: 3
    }
}));

const RedStar = () => {

    const classes = useStyles();

    return(
        <span className={classes.root}>*</span>
    );
}

export default RedStar;