import { Box, Container, Grid, makeStyles, Typography, Card, CardContent} from "@material-ui/core";
import { Fragment, useEffect, useRef } from "react";
import NotificationBar from "../../component/NotificationBar";
import { Link } from 'react-router-dom';
import SystemTitle from "../../component/SystemTitle";
import useFetch from "../../hook/useFetch";
import { FormattedMessage, useIntl } from "react-intl";
import {React} from 'react';
import Popup from "../../component/Popup";
import PageTitle from "../../component/PageTitle";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        '&:hover': {
            transform: 'scale(1.01)'
        },
        width: '100%',
        borderRadius: 10,
        transition: '0.5s',
        cursor: 'pointer',
        minHeight: '100%'
    },
    cardWrapper: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    cardBackground: {
        backgroundColor: theme.palette.primary.light, 
    },
    cardIcon: {
        backgroundColor: theme.palette.primary.light,
    },
    cardIconImage: {
        width: 300,
    },
    cardInfo: {
        '&::before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            height: 10,
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.palette.primary.main,
        },
        backgroundColor: '#ffffff',
        padding: "5px 20px 20px 20px",
        position: 'relative'
    },
    cardTitle: {
        marginTop: 25,
        marginBottom: 25
    },
    cardContent: {
        marginBottom: 25
    }
}));

const HomePageCard = ({
    title,
    content,
    src,
    ...rest
}) => {

    const classes = useStyles();

    return (
        <Card className={classes.cardRoot} elevation={3} {...rest}>
            <div className={classes.cardWrapper}>
                <Grid container justify="center" alignItems="center" className={classes.cardIcon}>
                    <Grid item>
                        <img className={classes.cardIconImage} src={src} alt=""/>
                    </Grid>
                </Grid>
                <div className={classes.cardInfo}>
                    <Typography variant="displayBlock" className={[classes.cardTitle, "MuiTypography-root makeStyles-cardTitle-65 MuiTypography-h3"]}>
                        {title}
                    </Typography>
                    <Typography component="p" className={classes.cardContent}>
                        {content}
                    </Typography>
                </div>
            </div>
        </Card>  
    );
}

const HomePage = () => {

    const {entities: feeds, isLoading} = useFetch({
        api: '/announcement',
        initFilter: {
            status: 1,
            lightbox: 1
        }
    });

    const {entities: avaliablePopup} = useFetch({
        api: '/popup/getAvaliablePopup',
        initFilter: {
            display_status: 1,
        }
    });

    const intl = useIntl();

    return (
        <Fragment>
            <NotificationBar 
                feeds={feeds}
                to = {`/${localeToLangCode(intl.locale)}/latestNews`}
            />
            <Container>
                <SystemTitle><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>

                <PageTitle variant="displayBlock" className={"MuiTypography-h1"}>
                    <FormattedMessage id="homepage.title"/>
                </PageTitle>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Link to={`/${localeToLangCode(intl.locale)}/complain`} style={{ textDecoration: 'none' }}>
                            <HomePageCard
                                title={<FormattedMessage id="homepage.enquiry.title"/>}
                                content={<FormattedMessage id="homepage.enquiry.content"/>}
                                src={`${process.env.PUBLIC_URL}/img/Homepage-icons-01.png`}
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <Link to={`/${localeToLangCode(intl.locale)}/gift`} style={{ textDecoration: 'none' }}>
                            <HomePageCard
                                title={<FormattedMessage id="homepage.gift.title"/>}
                                content={<FormattedMessage id="homepage.gift.content"/>}
                                src={`${process.env.PUBLIC_URL}/img/Homepage-icons-02.png`}
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <Link to={`/${localeToLangCode(intl.locale)}/manage`} style={{ textDecoration: 'none' }}>
                            <HomePageCard
                                title={<FormattedMessage id="homepage.manage.title"/>}
                                content={<FormattedMessage id="homepage.manage.content"/>}
                                src={`${process.env.PUBLIC_URL}/img/Homepage-icons-03.png`}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        
            {
                avaliablePopup && 'announcement_id' in avaliablePopup && 'image' in avaliablePopup && avaliablePopup.image &&
                <Popup
                    popup={avaliablePopup}
                    delay={500}
                />
            }
        </Fragment>
    );
}

export default HomePage;