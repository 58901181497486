import {GOOGLE_MAP_API_KEY} from '../../config/constant';
import { GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import { useCallback, useState } from 'react';

const MapContainer = ({
    position,
    isLoading
}) => {

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_API_KEY
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => {
        
        setMap(map);
    }, []);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    return isLoaded && !isLoading ? (
        <GoogleMap
          mapContainerStyle={{
              width: '100%',
              height: '95%',
              minHeight: 400,
              margin: 'auto',
              borderRadius: 10
          }}
          center={position}
          zoom={19}
          onLoad={onLoad}
          onUnmount={onUnmount}
          clickableIcons={false}
          options={{
            disableDefaultUI: true
          }}
        >
            <Marker
                position={position}
                draggable={false}
                clickable={false}
            />
        </GoogleMap>
    ) : <></>
}

export default MapContainer;