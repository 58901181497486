import { Container, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import SystemTitle from "../../component/SystemTitle";
import externalLink from "../../config/externalLink";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    link: {
        color: "#1ca592",
        fontWeight: 700,
        textDecoration: 'none',
        '&:hover': {
            color: 'inherit',
            opacity: '.8',
            marginLeft: 5
        },
        fontSize: 20,
        transition: '.3s',
    },
    linkWrapper: {
        margin: '10px 0',
    }
}));

const SitemapPage = () => {
    const classes = useStyles();

    const intl = useIntl();

    return (
        <Container>
            <SystemTitle><FormattedMessage id="sitemap.title"/></SystemTitle>

            <div>
                <div className={classes.linkWrapper}><Typography component={Link} to={`/${localeToLangCode(intl.locale)}/`} className={classes.link}><FormattedMessage id="sitemap.home"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={`/${localeToLangCode(intl.locale)}/latestNews`} className={classes.link}><FormattedMessage id="sitemap.latest.news"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={`/${localeToLangCode(intl.locale)}/complain`} className={classes.link}><FormattedMessage id="sitemap.enquiry.complaint"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={`/${localeToLangCode(intl.locale)}/gift`} className={classes.link}><FormattedMessage id="sitemap.gift.redemption"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={`/${localeToLangCode(intl.locale)}/manage`} className={classes.link}><FormattedMessage id="sitemap.manage.confirmed.bookings"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={{pathname: externalLink['copyright'][intl.locale]}} target="_blank" className={classes.link}><FormattedMessage id="sitemap.copyright.disclaimer"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={{pathname: externalLink['codeOnAccess'][intl.locale]}} target="_blank" className={classes.link}><FormattedMessage id="sitemap.code.on.access.to.information"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={{pathname: externalLink['privacy'][intl.locale]}} target="_blank" className={classes.link}><FormattedMessage id="sitemap.privacy"/></Typography></div>

                <div className={classes.linkWrapper}><Typography component={Link} to={{pathname: externalLink['accessibilityStatement'][intl.locale]}} target="_blank" className={classes.link}><FormattedMessage id="sitemap.accessibility.statement"/></Typography></div>
            </div>
        </Container>
    );
}

export default SitemapPage;