import { Box, Slide, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {React} from 'react';
import WcagIconButton from "../../WcagIconButton";
import moment from "moment";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    switchHeader: {
        display: 'flex',
        marginTop: '4px',
        alignItems: 'center',
        marginBottom: '8px',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: '1.7rem',
        fontWeight: 'bold'
    }
}));

const SwitchHeader = ({date, nextMonth, lastMonth, disableLastMonth}) => {

    const classes = useStyles();

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const intl = useIntl();

    const switchHeaderText = moment(date).locale(intl.locale).format('MMM YYYY');

    return (
        <div className={classes.switchHeader}>
            <WcagIconButton className={classes.margin} onClick={lastMonth} disabled={disableLastMonth} label="lastMonth">
                <ChevronLeftIcon fontSize="large"/>
            </WcagIconButton>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                <Typography variant="displayBlock" className={[classes.text, 'MuiTypography-h2']}>
                    {switchHeaderText}
                </Typography>
            </Slide>
            <WcagIconButton className={classes.margin} onClick={nextMonth} label="nextMonth">
                <ChevronRightIcon fontSize="large"/>
            </WcagIconButton>
        </div>
    );
}

export default SwitchHeader;