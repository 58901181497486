import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatTime } from "../../helper/dateTimeFormat";
import InfoText from "../InfoText";
import StyledButton from "../StyledButton";
import {React} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import fieldLangConvertor from '../../helper/fieldLangConvertor';
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px 40px',
        marginBottom: 30,
        borderRadius: 10,
        [theme.breakpoints.down("sm")]:{
            padding: '10px 20px',
        }
    },
    button: {
        '& > p > h3': {
            margin: 0
        },
        marginBottom: 15,
        width: '80%',
        height: 50,
        [theme.breakpoints.down("sm")]:{
            width: '100%'
        }
    },
    buttonWrapper: {
        textAlign: 'right',
        [theme.breakpoints.down("sm")]:{
            textAlign: 'center'
        }
    },
    title: {
        color: theme.palette.primary.main
    },
    text: {
        margin: '10px 0'
    }
}));

const RecordInfoBox = ({
    record,
    index,
    onCancel
}) => {
    const classes = useStyles();

    const { path, url } = useRouteMatch();
    const history = useHistory();
    const intl = useIntl();

    const handleUpdateButtonClick = () => {
        history.push(`/${localeToLangCode(intl.locale)}/manage/edit`, record);
    }

    return (
        <Paper className={classes.root} elevation={5}>
            <Grid container>
                <Grid item container xs={12} sm={8} md={9}>
                    <Grid item xs={12}>
                        <Typography>
                            <Box component="h2" className={classes.title}>
                                {`${intl.formatMessage({id: "managepage.listing.booking"})} ${index + 1}`}
                            </Box>
                        </Typography>
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.datetime"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{`${record.session.date} ${formatTime(record.session.start_time)}`}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.service"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{record.session.purpose_id == 1 ? <FormattedMessage id="giftpage.title"/> : <FormattedMessage id="complaintpage.title"/>}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.location"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{fieldLangConvertor(record.session.branch, 'branch_address', intl.locale)}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.ref"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{record.ref_code}</InfoText> 
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={3} className={classes.buttonWrapper}>
                    <StyledButton
                        label={intl.formatMessage({id: "managepage.listing.update"})}
                        filled  
                        className={classes.button}
                        onClick={handleUpdateButtonClick}
                    />

                    <StyledButton
                        label={intl.formatMessage({id: "managepage.listing.cancel"})}
                        className={classes.button}
                        onClick={onCancel}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default RecordInfoBox;