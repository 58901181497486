import { Grid, makeStyles } from "@material-ui/core";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 50,
        marginBotton: 50,
    }
}));

const ConfirmLogo = () => {

    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item>
                <img 
                    src={`${process.env.PUBLIC_URL}/img/confirm-logo.png`}
                    alt="Confirm Logo"
                />
            </Grid>
        </Grid>
    );
}

export default ConfirmLogo;