import { AppBar, Container, Divider, Grid, IconButton, List, ListItem, ListItemText, makeStyles, SwipeableDrawer, Toolbar, Typography} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import { Fragment, React, useState } from "react";
import { Link } from "react-router-dom";
import breakpoint from "../../config/breakpoint";
import LangSwitcher from "../LangSwitcher";
import { useBreakpoints } from 'react-breakpoints-hook';
import MenuIcon from '@material-ui/icons/Menu';
import { useIntl } from "react-intl";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#ffffff'
    },
    divider:{
        width: 2,
        height: 20
    },
    logoWrapper: {
        maxWidth: "100%"
    },
    logo: {
        maxWidth: "100%"
    },
    right: {

    },
    shareButton: {
        [theme.breakpoints.down("xs")]:{
            fontSize: 14
        }
    },
    menuButton: {

    },
    drawerItem: {
        display: 'block',
        backgroundColor: '#eaeaea'
    },
    mobileLangSwitcher: {
        minWidth: 250,
        backgroundColor: '#ffffffd1'
    },
    mobileLangSwitcherContainer: {
        padding: 10
    }
}));

const Header = ({
    lang,
    setLang
}) => {

    const classes = useStyles();

    const {xs, sm, md, lg} = useBreakpoints(breakpoint);

    const [draweOpen, setDrawerOpen] = useState(false);

    const intl = useIntl();

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    }

    return (
        <Fragment>
            <AppBar position="sticky" className={classes.header}>
                <Container>
                    <Grid container justify="space-between">
                        <Grid item xs={10} sm={8}>
                            <Link to={`/${localeToLangCode(intl.locale)}`}>
                                <div className={classes.logoWrapper}>
                                    <img className={classes.logo} src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Logo"/>
                                </div>
                            </Link>
                        </Grid>

                        {
                            <Grid container item xs={2} sm={4} justify="flex-end" alignItems="center">
                                {
                                    xs ? 
                                    <IconButton 
                                        edge="end" 
                                        className={classes.menuButton} 
                                        color="inherit" 
                                        aria-label="menu" 
                                        onClick={toggleDrawer(true)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    :
                                    <>
                                        <Grid item className={classes.langSwitcher}>
                                            <LangSwitcher
                                                lang={lang}
                                                setLang={setLang}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        }
                    </Grid>
                </Container>
            </AppBar>

            {
                xs ?
                <SwipeableDrawer
                    anchor="right"
                    open={draweOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    swipeAreaWidth={100}
                    classes={{
                        paper: classes.mobileLangSwitcher
                    }}
                >
                    <div
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        className={classes.mobileLangSwitcherContainer}
                    >
                        <LangSwitcher
                            lang={lang}
                            setLang={setLang}
                        />
                    </div>
                </SwipeableDrawer>
                : ""
            }
        </Fragment>
    );
}

export default Header;