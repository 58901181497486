const breakpoint = {
    xs: {
        min: 0,
        max: 600
    },
    sm: {
        min: 601,
        max: 960
    },
    md: {
        min: 961,
        max: 1400
    },
    lg: {
        min: 1401,
        max: null
    }
}

export default breakpoint;