import { Dialog, IconButton, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { getURLFromPublic } from "../../config/api";
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from "react-intl";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: '100%'
    },
    popupContainer: {
        padding: 10
    },
    closeButton: {
        position: 'absolute',
        top: 0, 
        right: 0
    }
}));

const Popup = ({
    popup,
    delay = 1000,
}) => {

    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const intl = useIntl();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        if (popup && 'announcement_id' in popup && 'image' in popup){
            setTimeout(()=>{
                setOpen(true);
            }, delay);
        }
    }, [popup]);



    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <div className={classes.popupContainer}>
            {
                popup && <Link to={`/${localeToLangCode(intl.locale)}/latestNews/${popup.announcement_id}`}><img src={getURLFromPublic(popup.image.path)} className={classes.image} alt={popup.image.name}/></Link>
            }
            <IconButton aria-label="close" className={classes.closeButton} size="small" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
            </IconButton>
            </div>
        </Dialog>
    );
}

export default Popup;