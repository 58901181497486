import { useEffect, useState } from "react";
import { get } from "../config/api";

const usePaginationFetch = ({
    api,
    initPageSize = 5,
    initFilter = {},
}) => {
    const [entities, setEntities] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(initPageSize);
    const [total, setTotal] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState(initFilter);

    useEffect(() => {

        const fetchEntity = () => {
            if (!api) return;
    
            const params = new URLSearchParams({
                page: page,
                pageSize: pageSize,
                ...filter
            });
            
            setLoading(true);
            get(`/paginate${api}?${params.toString()}`)
            .then(res => {
                setTotal(res['total']);
                setEntities(res['data']);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
        }

        fetchEntity();
    }, [page, pageSize, filter]);

    const handlePageChange = (params) => {
        setPage(params.page + 1);
    }

    return {
        entities,
        isLoading,
        page,
        pageSize,
        total,
        handlePageChange,
    };
}

export default usePaginationFetch;