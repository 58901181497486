import GeneralBooking from "../../component/GeneralBooking";
import {React} from 'react';
import { FormattedMessage } from "react-intl";

const GiftPage = () => {
    return (
        <GeneralBooking
            title={<FormattedMessage id="giftpage.title"/>}
            purposeId={1}
        />
    );
}

export default GiftPage