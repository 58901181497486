import GeneralBooking from "../../component/GeneralBooking";
import {Fragment, React, useState} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import PageTitle from "../../component/PageTitle";
import InfoText from "../../component/InfoText";
import StyledButton from "../../component/StyledButton";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    list: {
        marginLeft: -20
    },
    last: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 24,
        marginTop: 30
    },
    title: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 24
    },
    text: {
        color: 'black',
        fontSize: '1.5rem',
        marginBlockStart: "0.83em",
        marginBlockEnd: "0.83em",
    },
    controlAreaButtonGroup: {
        marginTop: 10,
        justifyContent: 'flex-end',
        [theme.breakpoints.down("sm")]:{
            justifyContent: 'center'
        }
    },
    controlAreaForwardButton: {
        width: 250,
        [theme.breakpoints.down("sm")]:{
            width: '100%'
        }
    }
}));

const ComplainPage = () => {

    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(true);

    const history = useHistory();

    const handleClose = e => {
        setDialogOpen(false);
    }

    const handleNotAccept = e => {
        history.goBack();
    }

    const intl = useIntl();

    return (
        <Fragment>
            <GeneralBooking
                title={<FormattedMessage id="complaintpage.title"/>}
                purposeId={2}
            />
            <Dialog 
                onClose={handleClose} 
                open={dialogOpen}
                fullScreen
            >
                <DialogTitle id="reminder-dialog-title"></DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <ul className={classes.list}>
                            <li>
                                <InfoText component="div" className={classes.title} dangerouslySetInnerHTML={{__html: intl.formatMessage({id:"complain.dialog.content.title" })}}>
                                </InfoText>
                                <ul>
                                    <li>
                                        <InfoText component="div" className={classes.text} dangerouslySetInnerHTML={{__html: intl.formatMessage({id:"complain.dialog.content.para1" })}}></InfoText>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <InfoText component="div" className={classes.title}>
                                    <FormattedMessage id="complain.dialog.content.para2"/>
                                </InfoText>
                                <ul>
                                    <li><InfoText component="div" className={classes.text}><FormattedMessage id="complain.dialog.content.para2.point1"/></InfoText></li>
                                    <li><InfoText component="div" className={classes.text}><FormattedMessage id="complain.dialog.content.para2.point2"/></InfoText></li>
                                    <li><InfoText component="div" className={classes.text}><FormattedMessage id="complain.dialog.content.para2.point3"/></InfoText></li>
                                    <li><InfoText component="div" className={classes.text}><FormattedMessage id="complain.dialog.content.para2.point4"/></InfoText></li>
                                    <li><InfoText component="div" className={classes.text}><FormattedMessage id="complain.dialog.content.para2.point5"/></InfoText></li>
                                </ul>
                            </li>
                        </ul>
                        <InfoText component="div" className={classes.last}  dangerouslySetInnerHTML={{__html: intl.formatMessage({id:"complain.dialog.content.last" })}}>
                        </InfoText>

                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Grid container spacing={3} className={classes.controlAreaButtonGroup}>
                        <Grid item className={classes.controlAreaForwardButton}>
                            <StyledButton
                                label={intl.formatMessage({id:"complain.dialog.back" })}
                                onClick={handleNotAccept}
                                variant="div"
                                className="MuiTypography-h3"
                            />
                        </Grid>
                        <Grid item className={classes.controlAreaForwardButton}>
                            <StyledButton
                                label={intl.formatMessage({id:"complain.dialog.accept" })}
                                filled
                                onClick={handleClose}
                                type="button"
                                variant="div"
                                className="MuiTypography-h3"
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}


export default ComplainPage;