import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { formatDate, formatTime } from "../../../helper/dateTimeFormat";
import useFetch from "../../../hook/useFetch";
import BookingFormLabel from "../../BookingFormLabel";
import BookingFormSelectInput from "../../BookingFormSelectInput";
import BookingFormWrapper from "../../BookingFormWrapper";

Date.prototype.addDays = function(days) {
    let dat = new Date(this.valueOf()); // (1)
    dat.setDate(dat.getDate() + days);  // (2)
    return dat
}

const MobilePicker = ({
    purposeId, 
    branchId,
    value,
    onChange
}) => {

    const intl = useIntl();

    const {entities: avaliableDates, setReady: setDateReady, handleFilterChange: handleDateFilterChange} = useFetch({
        api: '/session/avaliableDate',
        initFilter: {
            purpose: purposeId,
            branch: branchId
        },
        readyToFetch: false
    });

    const [date, setDate] = useState(null);

    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const handleSessionChange = (e) => {
        if (sessions?.length){
            onChange(sessions.find(s => s.id == e.target.value));
        }
    }

    const {entities: sessions, setReady: setSessionReady, handleFilterChange: handleSessionFilterChange} = useFetch({
        api: '/session',
        initFilter: {
            date: date,
            purpose: purposeId,
            branch: branchId
        },
        readyToFetch: false
    });

    useEffect(() => {
        if (sessions?.length){
            onChange(sessions.find(s => s.avaliable));
        }
    }, [sessions]);

    useEffect(() => {
        if (avaliableDates?.length){
            setDate(avaliableDates.find(obj => obj.avaliable)?.date);
        }
    }, [avaliableDates]);

    useEffect(() => {
        if (branchId){
            setDateReady(true);
        }else{
            setDateReady(false);
        }

        handleDateFilterChange({
            purpose: purposeId,
            branch: branchId
        })
    }, [branchId]);

    useEffect(() => {
        if (date && branchId){
            setSessionReady(true);
        }else{
            setSessionReady(false);
        }

        handleSessionFilterChange({
            date: date,
            purpose: purposeId,
            branch: branchId
        })
    }, [date, branchId]);

    return (
        <form>
            <BookingFormWrapper>
                <BookingFormLabel label={intl.formatMessage({id: "session.picker.available.date"})} required/>
                <BookingFormSelectInput
                    options={(avaliableDates||[]).map((obj) => ({
                        value: obj.date,
                        label: obj.date,
                        disable: !obj.avaliable
                    }))}
                    onChange={handleDateChange}
                    value={date}
                />
            </BookingFormWrapper>

            <BookingFormWrapper>
                <BookingFormLabel label={intl.formatMessage({id: "session.picker.available.timeslot"})} required/>
                <BookingFormSelectInput
                    options={(sessions||[]).map((session) => {
                        const active = session.active;
                        const fulled = session.occupied >= session.booking_capacity;
                        const openedToBook = session.openedToBook;
                        const closedToBook = session.closedToBook;

                        const cannotBookYet = (!openedToBook && !closedToBook);
                        const expiriedToBook = (openedToBook && closedToBook);

                        const disable = !active || fulled || cannotBookYet || expiriedToBook;

                        return ({
                            value: session.id,
                            label: formatTime(session.start_time),
                            disable: disable
                        });
                    })}
                    value={value ? value.id : ''}
                    onChange={handleSessionChange}
                />
            </BookingFormWrapper>
        </form>
    )
}

export default MobilePicker;