import { IconButton } from "@material-ui/core";

const WcagIconButton = ({
    children,
    label,
    ...rest
}) => {
    return (
        <IconButton {...rest} style={{
            fontSize: 0
        }}>
            {label}
            {children}
        </IconButton>
    );
}

export default WcagIconButton;