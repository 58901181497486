import { Redirect } from "react-router-dom";
import BookingDetails from "../../component/BookingDetails";
import {React} from 'react';
import { useIntl } from "react-intl";

const EditDetailsPage = ({location}) => {

    const recordState = location.state;
    const intl = useIntl();

    if (!recordState){
        return <Redirect to="/"/>;
    }

    const recipient = recordState.primary_contact_type_id == 1 ? recordState.mobile : recordState.email;

    return (
        <BookingDetails
            recordState={recordState}
            title={intl.formatMessage({id: 'booking.detail.updated.title'})}
            subTitle={`${intl.formatMessage({id: 'booking.detail.email.sent'})} ${recipient}`}
        />
    );
}

export default EditDetailsPage;