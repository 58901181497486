import { Box, makeStyles, Typography } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import BackToHomeButton from "../../../component/BackToHomeButton";
import { get, getURLFromPublic } from "../../../config/api";
import {React} from 'react';
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import fieldLangConvertor from "../../../helper/fieldLangConvertor";
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';

const useStyles = makeStyles((theme) => ({
    content: {
        wordBreak: 'break-word'
    },
    image: {
        maxWidth: '100%'
    },
    updatedAt: {
        color: theme.palette.primary.main,
        marginBottom: 10
    },
    title: {
        wordBreak: 'break-word',
        textAlign: 'left'
    }
}));

const DetailPage = ({match}) => {

    const id = match.params.id;

    const [post, setPost] = useState(null);

    const classes = useStyles();
    const intl = useIntl();

    const [updateAt, setUpdateAt] = useState(null);

    useEffect(() => {
        const getPost = async () => {
            const res = await get(`/announcement/${id}`);
            setPost(res);
        }
        
        getPost();
    }, []);

    useEffect(() => {
        if (post){
            if (intl.locale == 'zh'){ //cn
                setUpdateAt(moment(post.updated_at).locale('zh-cn').format('HH:mm, YYYY年MM月DD日'));
            }else if (intl.locale == 'zh-hant'){ // hk
                setUpdateAt(moment(post.updated_at).locale('zh-hk').format('HH:mm, YYYY年MM月DD日'));
            }else{
                setUpdateAt(moment(post.updated_at).locale('en').format('HH:mm MMM D, YYYY'));
            }
        }
    }, [intl.locale, post]);
     
    if (!post) return "";

    return (
        <Fragment>
            <Typography variant="h1" className={classes.title}>
                {fieldLangConvertor(post, 'title', intl.locale)}
            </Typography>
            <Typography variant="displayBlock" className={["MuiTypography-root MuiTypography-h3", classes.updatedAt]}>
                <FormattedMessage id="latest.news.update.at"/> {updateAt}
            </Typography>

            {
                post.image && <img src={getURLFromPublic(post.image.path)} className={classes.image}/>
            }

            <Typography>
                <Box component="p" className={classes.content}>
                    {fieldLangConvertor(post, 'description', intl.locale)}
                </Box>
            </Typography>
            <BackToHomeButton
                link="/latestNews"
                label={intl.formatMessage({id: 'complain.dialog.back'})}
                filled={false}
            />
        </Fragment>
    );
}

export default DetailPage;