import { Box, Typography } from "@material-ui/core";
import {React} from 'react';
import clsx from "clsx";

const InfoText = ({
    children,
    component = "h2",
    className,
    ...rest
}) => {

    return (
        <Typography>
            <Box component={component} fontWeight="normal" className={clsx(className)} {...rest}>
                {children}
            </Box>
        </Typography>
    );
}

export default InfoText;