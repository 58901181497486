import { Box, Container, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import SystemTitle from "../../component/SystemTitle";
import {React} from 'react';

const StatementPage = () => {
    return (
        <Container>
            <SystemTitle variant="displayInline"><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>

            <Typography variant="h1">
                <Box component="h1" textAlign="center">
                    <FormattedMessage id="statement.title"/>
                </Box>
            </Typography>

            <Typography>
                <Box component="p">
                    <FormattedMessage id="statement.content.part1"/>
                </Box>

                <Box component="ol" type="i">
                    <li><FormattedMessage id="statement.content.part2"/></li>
                    <li><FormattedMessage id="statement.content.part3"/></li>
                </Box>

                <Box component="p">
                    <FormattedMessage id="statement.content.part4"/>
                </Box>

                <Box component="p">
                    <FormattedMessage id="statement.content.part5"/>
                </Box>
            </Typography>
        </Container>
    );
}

export default StatementPage;