import CancelSuccessPage from "../page/CancelSuccessPage";
import ComplainPage from "../page/ComplainPage";
import SitemapPage from "../page/SitemapPage";
import EditDetailsPage from "../page/EditDetailsPage";
import GiftPage from "../page/GiftPage";
import HomePage from "../page/HomePage";
import LatestNewsPage from "../page/LatestNewsPage";
import DetailPage from "../page/LatestNewsPage/DetailPage";
import NewsListingPage from "../page/LatestNewsPage/ListingPage";
import ManagePage from "../page/ManagePage";
import EditPage from "../page/ManagePage/EditPage";
import EntryPage from "../page/ManagePage/EntryPage";
import ListingPage from "../page/ManagePage/ListingPage";
import StatementPage from "../page/StatementPage";
import ThankyouPage from "../page/ThankYouPage";

const routes = [
    {
        path: '/',
        component: HomePage,
        exact: true,
        breadcrumbName: 'Home'
    },
    {
        path: '/complain',
        component: ComplainPage,
        exact: true,
        breadcrumbName: 'Complain'
    },
    {
        path: '/gift',
        component: GiftPage,
        exact: true,
        breadcrumbName: 'Gift'
    },
    {
        path: '/statement',
        component: StatementPage,
        exact: true,
        breadcrumbName: 'Statement'
    },
    {
        path: '/thankyou',
        component: ThankyouPage,
        exact: true,
        breadcrumbName: 'Thankyou'
    },
    {
        path: '/editDetails',
        component: EditDetailsPage,
        exact: true,
        breadcrumbName: 'Thankyou'
    },
    {
        path: '/cancelConfirm',
        component: CancelSuccessPage,
        exact: true,
        breadcrumbName: 'Thankyou'
    },
    {
        path: '/latestNews',
        component: LatestNewsPage,
        breadcrumbName: 'Latest news',
        routes: [
            {
                path: '/latestNews',
                component: NewsListingPage,
                breadcrumbName: 'Latest news',
                exact: true,
            },
            {
                path: '/latestNews/:id',
                component: DetailPage,
                breadcrumbName: 'Latest news',
                exact: true,
            }
        ]
    },
    {
        path: '/manage',
        component: ManagePage,
        breadcrumbName: 'Manage',
        routes: [
            {
                path: '/manage',
                component: EntryPage,
                breadcrumbName: 'Manage',
                exact: true,
            },
            {
                path: '/manage/listing',
                component: ListingPage,
                breadcrumbName: 'Manage Listing',
                exact: true,
            },
            {
                path: '/manage/edit',
                component: EditPage,
                breadcrumbName: 'Manage Edit',
                exact: true,
            },
        ]
    },
    {
        path: '/sitemap',
        component: SitemapPage,       
    }
];

export default routes;