import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { AppBar, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {React, useRef, useState} from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import fieldLangConvertor from "../../helper/fieldLangConvertor";
import WcagIconButton from "../WcagIconButton";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#efefef',
        overflow: "hidden"
    },
    container: {
        padding: "10px 0",
    },
    bellIcon: {
        color: '#8c6800',
        fontSize: 30,
        maxWidth: '10%'
    },
    arrow: {
        color: 'rgba(0, 0, 0, 0.87)',
        verticalAlign: 'middle'
    },
    slider: {
        width: '100%',
        overflow: 'hidden'
    },
    feedWrapper: {
        padding: "5px 0",
    },
    feedTitle: {
        marginLeft: 10,
        maxWidth: 'calc(90% - 10px)',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'inline-block'
    },
    link: {
        textDecoration: 'none',
        color: 'unset',
        verticalAlign: 'middle'
    },
    latestNews: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.87)',
        marginLeft: 5,
        marginRight: 5,
    },
    latestNewsWrapper: {
        [theme.breakpoints.down("xs")]:{
            justifyContent: 'flex-start'
        }
    },
    playPauseWrapper: {
        display: 'block',
        [theme.breakpoints.down("xs")]:{
            display: 'flex'
        }
    },
    playPauseRoot: {
        padding: 0,
        color: '#000000',
        fontSize: 0
    }
}));

const PlayPauseButton = ({
    playing,
    onClick
}) => {

    const classes = useStyles();

    return (
        <WcagIconButton className={classes.playPauseRoot} onClick={onClick} label={playing ? "Pause":"Play"}>
            {
                playing ? 
                <PauseCircleOutlineIcon/>
                :
                <PlayCircleOutlineIcon/>
            }
        </WcagIconButton>
    );
}

const Feed = ({
    content
}) => {

    const classes = useStyles();

    return (
        <div className={classes.feedWrapper}>
            <NotificationsNoneIcon className={classes.bellIcon}/>
            <Typography component="p" className={classes.feedTitle} noWrap={true}>
                <Box fontSize={18} component="span">
                    {content}
                </Box>
            </Typography>
        </div>
    );
}

const NotificationBar = ({
    feeds = [],
    to
}) => {

    const classes = useStyles();

    const [playing, setPlaying] = useState(true);
    const slider = useRef();
    const intl = useIntl();

    const handlePlayButtonClick = () => {
        if (playing){
            slider.current.slickPause();   
        }else{
            slider.current.slickPlay(); 
        }
        setPlaying(!playing);
    }

    return (
        <AppBar position="static" className={classes.root} elevation={0}>
            <Container>
                <Grid container className={classes.container}>
                    <Grid container item xs={12} sm={8} md={8} alignItems="center" spacing={2}>
                        <Slider
                            ref={slider}
                            dots={false}
                            infinite={true}
                            speed={2000}
                            autoplay={true}
                            slidesToShow={1}
                            slidesToScroll={1}
                            slidesPerRow={1}
                            adaptiveHeight={true}
                            vertical={true}
                            arrows={false}
                            className={classes.slider}
                        >
                            {
                                (feeds || []).map((feed, index) => <Feed key={index} content={fieldLangConvertor(feed, 'title', intl.locale)}/>)
                            }
                        </Slider>
                    </Grid>
                    <Grid className={classes.latestNewsWrapper} container item xs={12} sm={4} md={4} justify="flex-end" spacing={1} alignItems="center">
                        <Grid item>
                            <PlayPauseButton
                                playing={playing}
                                onClick={handlePlayButtonClick}
                            />
                            <Link to={to} className={classes.link}>
                                <Typography component="span" className={classes.latestNews}>
                                    <FormattedMessage id="notification.latestNews"/>
                                </Typography>
                                <ArrowForwardIosIcon className={classes.arrow}/>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    );
}

export default NotificationBar;