const DOMAIN = 'https://www.consumer.org.hk';
const COPYRIGHT_ENDPOINT = 'copyright-and-disclaimer';
const CODEONACCESS_ENDPOINT = 'code-on-access-to-information';
const PRIVACY_ENDPOINT = 'privacy-policy';
const ACCESSIBILITY_ENDPOINT = 'accessibility-statement';

const externalLink = {
    'copyright': {
        'en': `${DOMAIN}/en/${COPYRIGHT_ENDPOINT}`,
        'zh-hant': `${DOMAIN}/tc/${COPYRIGHT_ENDPOINT}`,
        'zh': `${DOMAIN}/sc/${COPYRIGHT_ENDPOINT}`
    },
    'codeOnAccess': {
        'en': `${DOMAIN}/en/${CODEONACCESS_ENDPOINT}`,
        'zh-hant': `${DOMAIN}/tc/${CODEONACCESS_ENDPOINT}`,
        'zh': `${DOMAIN}/sc/${CODEONACCESS_ENDPOINT}`
    },
    'privacy': {
        'en': `${DOMAIN}/en/${PRIVACY_ENDPOINT}`,
        'zh-hant': `${DOMAIN}/tc/${PRIVACY_ENDPOINT}`,
        'zh': `${DOMAIN}/sc/${PRIVACY_ENDPOINT}`
    },
    'accessibilityStatement': {
        'en': `${DOMAIN}/en/${ACCESSIBILITY_ENDPOINT}`,
        'zh-hant': `${DOMAIN}/tc/${ACCESSIBILITY_ENDPOINT}`,
        'zh': `${DOMAIN}/sc/${ACCESSIBILITY_ENDPOINT}`
    }
}

export default externalLink;