const fieldLangConvertor = (obj, field, lang = 'en') => {

    if (lang == 'zh-hant' && `${field}_tc` in obj && obj[`${field}_tc`]){
        return obj[`${field}_tc`];
    }

    if (lang == 'zh' && `${field}_sc` in obj && obj[`${field}_sc`]){
        return obj[`${field}_sc`];
    }

    return obj[`${field}`];
}

export default fieldLangConvertor;