import GeneralEditBooking from "../../../component/GeneralEditBooking";
import {React} from 'react';

const EditPage = ({location}) => {

    const record = location.state;

    return <GeneralEditBooking
        record={record}
    />;
}

export default EditPage;