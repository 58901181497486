import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { useEffect } from "react";
import { SessionPickerContext } from "../../context/SessionPickerContext";
import { formatDate } from "../../helper/dateTimeFormat";
import useDate from "../../hook/useDate";
import useFetch from "../../hook/useFetch";
import Calender from "./component/Calender"
import SessionList from "./component/SessionList";
import SwitchHeader from "./component/SwitchHeader";
import {React} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import breakpoint from "../../config/breakpoint";
import { useBreakpoints } from 'react-breakpoints-hook';
import BookingFormWrapper from "../BookingFormWrapper";
import BookingFormLabel from "../BookingFormLabel";
import DesktopPicker from "./component/DesktopPicker";
import MobilePicker from "./component/MobilePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        border: '1px solid rgb(0 0 0 / 40%)'
    }
}));

const SessionPicker = ({
    purposeId, 
    branchId,
    value,
    onChange
}) => {

    const classes = useStyles();

    const {xs, sm, md, lg} = useBreakpoints(breakpoint);

    const {date, setDate, nextMonth, lastMonth, disableLastMonth} = useDate({
        checkDisableDate: (date) => {
            return (date.getDay() == 0 || date.getDay() == 6)
        }
    });

    const {entities: sessions, setReady, handleFilterChange} = useFetch({
        api: '/session',
        initFilter: {
            date: formatDate(date),
            purpose: purposeId,
            branch: branchId
        },
        readyToFetch: false
    });

    useEffect(() => {
        if (date && branchId){
            setReady(true);
        }else{
            setReady(false);
        }

        handleFilterChange({
            date: formatDate(date),
            purpose: purposeId,
            branch: branchId
        })
    }, [date, branchId]);

    if (xs || sm){
        return (
            <MobilePicker
                purposeId = {purposeId}
                branchId = {branchId}
                value = {value}
                onChange = {onChange}
            />
        )
    }
    
    return (
        <DesktopPicker
            purposeId = {purposeId}
            branchId = {branchId}
            value = {value}
            onChange = {onChange}
        />
    )
}

export default SessionPicker;