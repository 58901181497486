import { Backdrop, Box, CircularProgress, FormControlLabel, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, React } from "react";
import useFetch from "../../hook/useFetch";
import MapContainer from "../MapContainer";
import StyledRadio from "../StyledRadio";
import { useBreakpoints } from 'react-breakpoints-hook';
import breakpoint from "../../config/breakpoint";
import { useIntl } from "react-intl";
import fieldLangConvertor from "../../helper/fieldLangConvertor";

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    checkBoxRoot: {
        padding: "20px 10px",
        cursor: 'pointer',
        borderRadius: 10,
        marginBottom: 15
    },
    selectedCheckBoxRoot: {
        border: '1px solid #000000'
    },
    branchName: {
        margin: 0,
        fontSize: '1.17em',
        fontWeight: 'bold',
    },
    branchAddress: {
        margin: 0,
        fontSize: "1.17em",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main,
        position: 'absolute'
    },
    formLabel: {
        fontSize: 0
    }
}));

const LocationCheckBox = ({
    branchName,
    branchAddress,
    selected = false,
    onClick = () => {}
}) => {

    const classes = useStyles();

    return (
        <Paper variant="outlined" className={clsx(classes.checkBoxRoot, {[classes.selectedCheckBoxRoot]: selected})} onClick={onClick}>
            <Grid container>
                <Grid item xs={2} sm={1}>
                    <FormControlLabel 
                        control={<StyledRadio/>} 
                        checked={selected} 
                        label="checkbox"
                        classes={{
                            label: [classes.formLabel]
                        }}
                    />                
                </Grid>

                <Grid item xs={10} sm={11}>
                    <Typography>
                        <Box component="div" className={classes.branchName}>
                            {branchName}
                        </Box>
                        <Box component="div" fontWeight="normal" className={classes.branchAddress}>
                            {branchAddress}
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

const LocationPicker = ({
    value,
    onChange,
    purposeId,
}) => {

    const classes = useStyles();

    const {xs, sm, md, lg} = useBreakpoints(breakpoint);
    const intl = useIntl();

    const {entities: branches, isLoading: isBranchLoading} = useFetch({
        api: '/branch',
        initFilter: {
            purpose: purposeId,
            status: 1
        }
    });

    const handleCheckBoxClick = (branch) => (e) => {
        onChange(branch);
    }

    useEffect(()=>{
        if (!value && branches && (md || lg)){
            onChange(branches[0]);
        }
    }, [branches, onChange, value]);

    const branchId = value ? value.id : null;

    const mapPosition = value ? {
        lat: Number(value.latitude),
        lng: Number(value.longitude)
    } : {};

    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={isBranchLoading}>
                <CircularProgress />
            </Backdrop>

            <Grid container spacing={3}>
                {
                    value && <Grid item xs={12} md={6}>
                        <MapContainer
                            position={mapPosition}
                            isLoading={isBranchLoading}
                        />
                    </Grid>
                }

                <Grid item xs={12} md={6}>
                    {
                        (branches || []).map(branch => 
                            <LocationCheckBox
                                key={branch.id}
                                branchName={fieldLangConvertor(branch, 'branch_name', intl.locale)}
                                branchAddress={fieldLangConvertor(branch, 'branch_address', intl.locale)}
                                selected={branch.id == branchId}
                                onClick={handleCheckBoxClick(branch)}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default LocationPicker;