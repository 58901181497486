import { useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from "react-intl";

const ReCaptcha = ({
    sitekey,
    formik,
    recaptchaRef
}) => {

    const intl = useIntl();

    const handleChange = useCallback((sign) => {
        if (sign){
            formik.setFieldValue('captcha', true);
            formik.setFieldValue('captcha_sign', sign);
        }else{
            formik.setFieldValue('captcha', false);
            formik.setFieldValue('captcha_sign', null);
        }
    }, [formik]);

    const [hl, setHl] = useState('en');
    const [key, setKey] = useState(Math.random().toString());

    useEffect(() => {
        if (intl.locale == 'zh'){
            setHl('zh-CN');
        }else if (intl.locale == 'zh-hant'){
            setHl('zh-HK');
        }else{
            setHl('en');
        }
        setKey(Math.random().toString());
    }, [intl.locale]);

    const onload = async () => {
        let textarea = document.getElementById("g-recaptcha-response");
        if (textarea){
            textarea.setAttribute("aria-hidden", "true");
            textarea.setAttribute("aria-label", "do not use");
            textarea.setAttribute("aria-readonly", "true");
        }
    }

    return (
        <ReCAPTCHA
            key={key}
            ref={recaptchaRef}
            sitekey={sitekey}
            onChange={handleChange}
            hl={hl}
            asyncScriptOnLoad={onload}
        />
    );
}

export default ReCaptcha;