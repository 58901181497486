import { Box, makeStyles, Typography } from "@material-ui/core";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    underline: {
        '&::after': {
            content: "''",
            display: 'block',
            height: 24,
            marginTop: -20,
            backgroundColor: '#c7f0eb',
        },
        display: 'inline-block',
    }
}));

const SystemTitle = ({children, variant = "h1"}) => {
    const classes = useStyles();

    return (
        <Typography variant={variant} className={[classes.underline, "MuiTypography-h1"]} >
            {children}
        </Typography>
    );
}

export default SystemTitle;