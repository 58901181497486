import { useEffect, useState } from "react";

const useDate = ({
    initDate = null,
    checkDisableDate = (date) => false
} = {}) => {
    
    const [date, setDate] = useState(initDate ? new Date(initDate) : new Date());
    const [disableLastMonth, setDisableLastMonth] = useState();
    
    const nextMonth = () => {
        let targetDate = date.getMonth() == 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1);
        while (checkDisableDate(targetDate)){
            targetDate.setDate(targetDate.getDate() + 1);
        }
        setDate(targetDate);
    }

    const lastMonth = () => {
        const lastMonthDate = date.getMonth() == 0 ? new Date(date.getFullYear() - 1, 11, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const today = new Date();
        let targetDate = lastMonthDate < today ? today : lastMonthDate;
        while (checkDisableDate(targetDate)){
            targetDate.setDate(targetDate.getDate() + 1);
        }
        setDate(targetDate);
    }

    const computeIsLastMonthAvaliable = () => {
        const lastMonth = date.getMonth() == 0 ? new Date(date.getFullYear() - 1, 11, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const today = new Date();
        const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        if (lastMonth < thisMonth){
            return true;
        }

        return false;
    }

    useEffect(() => {
        setDisableLastMonth(computeIsLastMonthAvaliable());
    }, [date]);

    return {
        date,
        disableLastMonth,
        setDate,
        nextMonth,
        lastMonth,
    }
}

export default useDate;